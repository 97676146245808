import useButtonsParam from '../hooks/useButtonsParam'
import { PropsWithChildren, ReactElement } from 'react'

export type ConfigurableUIComponentProps = PropsWithChildren & {
  id: string
  showByDefault: boolean
}

/**
 * Composant de l'interface utilisateur pouvant être caché.
 * @param props
 */
function ConfigurableUIComponent (props: ConfigurableUIComponentProps): ReactElement {
  const {
    children,
    id,
    showByDefault
  } = props
  const buttons = useButtonsParam()
  return (buttons && buttons.includes(id)) || (!buttons && showByDefault)
    ? <>{children}</>
    : null
}

export default ConfigurableUIComponent
