import useAuthContext from './modules/auth/useAuthContext'
import { useIntl } from 'react-intl'
import { Box, Button, Group, Modal, Text } from '@mantine/core'
import { ReactElement } from 'react'

function AuthExpiredNotifier (): ReactElement {
  const intl = useIntl()
  const {
    isAuthModalOpen,
    isExpired,
    isPending,
    setIsAuthModalOpen,
    signOut
  } = useAuthContext()

  if (isExpired && !isAuthModalOpen) {
    return (
      <Modal
        centered
        closeOnEscape={false}
        closeOnClickOutside={false}
        opened
        withCloseButton={false}
        onClose={() => signOut()}
        title={(
          <>
            {intl.formatMessage({
              id: 'auth_expired',
              defaultMessage: 'Authentification expirée'
            })}
          </>
        )}
      >
        <Text>
          {intl.formatMessage({
            id: 'reconnect_or_sign_out_msg',
            defaultMessage: 'Vous pouvez vous déconnecter ou vous reconnecter.'
          })}
        </Text>
        <Box>
          <Group mx="auto" pt="md" align="center" justify="center">
            <Button
              disabled={isPending}
              type="button"
              onClick={() => {
                signOut()
              }}
            >
              {intl.formatMessage({
                id: 'sign_out',
                defaultMessage: 'Se déconnecter'
              })}
            </Button>
            <Button
              disabled={isPending}
              type="button"
              onClick={() => {
                setIsAuthModalOpen(true)
              }}
            >
              {intl.formatMessage({
                id: 'sign_in',
                defaultMessage: 'Se connecter'
              })}
            </Button>
          </Group>
        </Box>
      </Modal>
    )
  }
  return null
}

export default AuthExpiredNotifier
