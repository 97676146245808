import { parseField } from '@jalik/form-parser'
import { ChangeEvent, FormEvent, ReactElement, useCallback, useState } from 'react'
import {
  COORD_FORMAT_DD,
  COORD_FORMAT_DM,
  COORD_FORMAT_DMS
} from '../../../enums/CoordinateFormats'
import {
  FORM_FAILED,
  FORM_READY,
  FORM_SENDING,
  FORM_SENT,
  FORM_UNCHANGED
} from '../../../enums/FormStates'
import {
  PREF_COORD_GEO_FORMAT,
  PREF_RESTORE_MAP_VIEW,
  PREF_SHOW_COORD_DFCI,
  PREF_SHOW_COORD_GEO,
  PREF_SHOW_COORD_UTM,
  PREF_SHOW_MAP_SCALE
} from '../../../enums/Preferences'
import { Box, Button, Checkbox, Group, Modal, ModalProps, Select, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import usePreferencesContext, { Preferences } from './usePreferencesContext'
import { logger } from '../../../lib/logger'
import { handleValueChange } from '../../../lib/form'
import { inputValue } from '@jalik/react-form'
import { useThrottledCallback } from '@mantine/hooks'

/**
 * Modale des préférences de l'utilisateur.
 * @param props
 */
function PreferencesModal (props: ModalProps): ReactElement {
  const {
    onClose,
    ...others
  } = props

  const {
    preferences,
    setPreferences
  } = usePreferencesContext()

  const [fields, setFields] = useState<Preferences>(preferences)
  const [status, setStatus] = useState<string>(FORM_UNCHANGED)

  const setField = useCallback((name: string, value: unknown) => {
    setFields({
      ...fields,
      [name]: value
    })
    setStatus(FORM_READY)
  }, [fields])

  const onSave = useThrottledCallback(() => {
    logger.debug('Saving preferences', fields)
    setStatus(FORM_SENDING)
    setPreferences({ ...preferences, ...fields })
    setStatus(FORM_SENT)
    onClose()
  }, 1000)

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    onSave()
  }, [onSave])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    setField(target.name, parseField(target))
  }, [setField])

  return (
    <Modal
      centered
      size="md"
      {...others}
      onClose={onClose}
      title={(
        <FormattedMessage
          id="preferences"
          defaultMessage="Préférences"
        />
      )}
    >
      <form method="post">
        <Stack>
          <Stack gap="sm">
            <Checkbox
              checked={!!fields[PREF_SHOW_COORD_GEO]}
              data-type="boolean"
              id="show-coord-geo-field"
              label="Afficher les coordonnées GPS"
              name={PREF_SHOW_COORD_GEO}
              onChange={handleChange}
              value="true"
            />

            <Select
              data-type="string"
              disabled={!fields[PREF_SHOW_COORD_GEO]}
              id="coord-geo-format-field"
              label="Format des coordonnées GPS"
              name={PREF_COORD_GEO_FORMAT}
              onChange={handleValueChange(PREF_COORD_GEO_FORMAT, setField)}
              required
              type="select"
              value={inputValue(fields[PREF_COORD_GEO_FORMAT])}
              data={[
                {
                  value: COORD_FORMAT_DD,
                  label: 'Degrés décimaux (lat, lon)'
                },
                {
                  value: COORD_FORMAT_DM,
                  label: 'Degrés, minutes (lat, lon)'
                },
                {
                  value: COORD_FORMAT_DMS,
                  label: 'Degrés, minutes, secondes (lat, lon)'
                }
              ]}
            />

            <Checkbox
              checked={!!fields[PREF_SHOW_COORD_UTM]}
              data-type="boolean"
              id="show-coord-utm-field"
              label="Afficher les coordonnées UTM"
              name={PREF_SHOW_COORD_UTM}
              onChange={handleChange}
              value="true"
            />
            <Checkbox
              checked={!!fields[PREF_SHOW_COORD_DFCI]}
              data-type="boolean"
              id="show-coord-dfci-field"
              label="Afficher les coordonnées DFCI"
              name={PREF_SHOW_COORD_DFCI}
              onChange={handleChange}
              value="true"
            />
            <Checkbox
              checked={!!fields[PREF_SHOW_MAP_SCALE]}
              data-type="boolean"
              id="show-map-scale-field"
              label="Afficher l&apos;échelle de la carte"
              name={PREF_SHOW_MAP_SCALE}
              onChange={handleChange}
              value="true"
            />
            <Checkbox
              checked={!!fields[PREF_RESTORE_MAP_VIEW]}
              data-type="boolean"
              id="restore-map-view-field"
              label="Restaurer la vue de carte à l'ouverture de l'application"
              name={PREF_RESTORE_MAP_VIEW}
              onChange={handleChange}
              value="true"
            />
          </Stack>

          <Text size="sm" c="dimmed">
            Note: Vos préférences sont enregistrées et chargées depuis le
            navigateur actuel.
          </Text>

          <Box>
            <Group justify="right">
              <Button
                variant="light"
                onClick={onClose}
                type="button"
              >
                <FormattedMessage
                  id="close"
                  defaultMessage="Fermer"
                />
              </Button>
              <Button
                disabled={status !== FORM_READY && status !== FORM_FAILED}
                onClick={handleSubmit}
                type="submit"
              >
                <FormattedMessage
                  id="save"
                  defaultMessage="Enregistrer"
                />
              </Button>
            </Group>
          </Box>
        </Stack>
      </form>
    </Modal>
  )
}

export default PreferencesModal
