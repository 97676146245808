import { useCallback, useEffect, useRef } from 'react'
import BaseEvent from 'ol/events/Event'
import Observable, { EventTypes } from 'ol/Observable'

function useOLEventListener<T extends Observable, E extends EventTypes> (
  target: T,
  event: E | any,
  listener: (...args: unknown[]) => unknown
) {
  const funcRef = useRef(null)

  const callback = useCallback((event: BaseEvent) => {
    if (typeof funcRef.current === 'function') {
      funcRef.current(event)
    }
  }, [])

  useEffect(() => {
    funcRef.current = listener
  }, [listener])

  useEffect(() => {
    if (target && event) {
      target.on(event, callback)
    }
    return () => {
      if (target && event) {
        target.un(event, callback)
      }
    }
  }, [target, event, callback])
}

export default useOLEventListener
