import { CSSProperties, useMemo } from 'react'
import useIsEmbedded from './useIsEmbedded'

/**
 * Taille maximale des cartes embarquées.
 */
export const EMBED_MAX_SIZE = 1000

function useEmbeddedStyles (): CSSProperties {
  const isEmbedded = useIsEmbedded()
  return useMemo(() => (
    isEmbedded
      ? {
        maxWidth: EMBED_MAX_SIZE,
        maxHeight: EMBED_MAX_SIZE
      }
      : {}), [isEmbedded])
}

export default useEmbeddedStyles
