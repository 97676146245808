import { createContext, useContext } from 'react'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Coordinate } from 'ol/coordinate'
import Route from './Route'
import { RouteAction, RouteState } from './RouteReducer'
import Feature from 'ol/Feature'

export type RouteSegmentId = number

export type RouteType = 'velo' | 'voiture' | 'pieton'

export type RouteContext_ = RouteState & {
  arrivalCoordinate?: Coordinate
  avoidGates: boolean
  calculating: boolean
  clear (): void
  clearError (): void
  departureCoordinate?: Coordinate
  dispatch (action: RouteAction): void
  error?: Error
  inversePoints (): void
  isListOpen: boolean
  isPlayerOpen: boolean
  markerLayer: VectorLayer<VectorSource>
  route?: Route
  routeLayer: VectorLayer<VectorSource>
  routeType: RouteType
  routeUrl: string
  segmentLayer: VectorLayer<VectorSource>
  setArrivalCoordinate (coordinate: Coordinate): void
  setAvoidGates (avoidGates: boolean): void
  setDepartureCoordinate (coordinate: Coordinate): void
  setRouteType (routeType: RouteType): void
  showRemoveModal: boolean
  showRouteTypeModal: boolean
  toggleList (): void
  togglePlayer (): void
  toggleRemoveModal (): void
  toggleRouteTypeModal (): void
  zoomToSegment (segment: Feature): void
}

/**
 * Contexte d'itinéraire.
 */
export const RouteContext = createContext<RouteContext_>(null)

/**
 * Retourne le contexte d'itinéraire.
 */
function useRouteContext () {
  return useContext(RouteContext)
}

export default useRouteContext
