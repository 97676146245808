import { useCallback, useEffect, useMemo, useState } from 'react'
import TilesLoader from './TilesLoader'

export function useTileLoader (loader: TilesLoader) {
  const [running, setRunning] = useState<boolean>(loader?.isRunning())
  const [, setV] = useState<number>(0)

  const update = useCallback(() => {
    setRunning(loader.isRunning())
    setV((s) => s + 1)
  }, [loader])

  useEffect(() => {
    if (loader) {
      loader.on('start', update)
      loader.on('stop', update)
      loader.on('update', update)
      return () => {
        if (loader) {
          loader.un('start', update)
          loader.un('stop', update)
          loader.un('update', update)
        }
      }
    }
  }, [update, loader])

  return useMemo(() => ({ running }), [running])
}
