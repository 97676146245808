import { HTMLAttributes, ReactElement } from 'react'
import { ActionIcon, ActionIconProps } from '@mantine/core'

export type MapToolbarButtonProps = {
  active?: boolean
}

/**
 * Bouton d'outil de carte.
 * @param props
 */
function MapToolbarButton (props: HTMLAttributes<HTMLElement> & ActionIconProps & MapToolbarButtonProps): ReactElement {
  const {
    active,
    children,
    ...others
  } = props

  return (
    <ActionIcon
      c={active ? 'active' : 'primary'}
      radius="xl"
      size="md"
      variant="default"
      {...others}
    >
      {children}
    </ActionIcon>
  )
}

export default MapToolbarButton
