import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import {
  FORM_CHANGED,
  FORM_FAILED,
  FORM_READY,
  FORM_SENDING,
  FORM_SENT,
  FORM_UNCHANGED
} from '../../../enums/FormStates'
import useObjectState from '../../../hooks/useObjectState'
import { createLogger } from '../../../lib/logger'
import useAuthContext from './useAuthContext'
import { notifications } from '@mantine/notifications'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Alert,
  Box,
  Button,
  Group,
  Modal,
  ModalProps,
  PasswordInput,
  Stack,
  TextInput
} from '@mantine/core'
import { handleFieldChange } from '../../../lib/form'

const logger = createLogger({ name: 'auth' })

function AuthModal (props: ModalProps): ReactElement {
  const {
    onClose,
    opened,
    ...others
  } = props

  const intl = useIntl()
  const { signIn } = useAuthContext()

  const [errorMessage, setErrorMessage] = useState<string>(null)
  const [formStatus, setFormStatus] = useState<string>(FORM_UNCHANGED)
  const [fields, setFields] = useObjectState({
    username: null,
    password: null
  })

  const setFieldValue = useCallback((name: string, value: string) => {
    setFields({ [name]: value })
    setFormStatus(FORM_CHANGED)
  }, [setFields])

  const handleFormSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    setFormStatus(FORM_SENDING)
    signIn(fields.username, fields.password)
      .then((json: any) => {
        const { content } = json

        setFormStatus(FORM_SENT)
        onClose()

        // Affiche un message de confirmation.
        if (content.displayName || content.username) {
          notifications.show({
            id: 'welcome-user',
            title: intl.formatMessage({
              id: 'signed_in',
              defaultMessage: 'Connecté'
            }, {
              name: <b>{content.displayName || content.username}</b>
            }),
            message: intl.formatMessage({
              id: 'connected_as_%',
              defaultMessage: 'Vous êtes connecté en tant que {name}.'
            }, {
              name: <b>{content.displayName || content.username}</b>
            }),
            color: 'info',
            icon: <FontAwesomeIcon icon={faUser} />
          })
        }
      })
      .catch((error: Error) => {
        logger.error(error.message)
        setFormStatus(FORM_FAILED)
        setErrorMessage(error.message)
      })
  }, [fields.password, fields.username, intl, onClose, signIn])

  useEffect(() => {
    if (fields.username && fields.password) {
      setFormStatus(FORM_READY)
    }
  }, [fields.password, fields.username])

  useEffect(() => {
    if (opened) {
      setFields({
        username: null,
        password: null
      })
    }
  }, [opened, setFields])

  return (
    <Modal
      centered
      opened={opened}
      size="sm"
      title={(
        intl.formatMessage({
          id: 'authentication',
          defaultMessage: 'Authentification'
        })
      )}
      {...others}
      onClose={onClose}
    >
      <form
        method="post"
        onSubmit={handleFormSubmit}
      >
        <Stack>
          <TextInput
            data-type="string"
            leftSection={(
              <FontAwesomeIcon
                icon={faUser}
                fixedWidth
              />
            )}
            name="username"
            onChange={handleFieldChange(setFieldValue)}
            placeholder={intl.formatMessage({
              id: 'username',
              defaultMessage: 'Nom d\'utilisateur'
            })}
            required
            value={fields.username || ''}
          />
          <PasswordInput
            data-type="string"
            leftSection={(
              <FontAwesomeIcon
                icon={faLock}
                fixedWidth
              />
            )}
            name="password"
            onChange={handleFieldChange(setFieldValue)}
            placeholder={intl.formatMessage({
              id: 'password',
              defaultMessage: 'Mot de passe'
            })}
            required
            value={fields.password || ''}
          />

          {errorMessage
            ? <Alert color="red">{errorMessage}</Alert>
            : null}

          <Box>
            <Group grow>
              <Button
                onClick={onClose}
                type="button"
                variant="light"
              >
                <FormattedMessage
                  id="close"
                  defaultMessage="Fermer"
                />
              </Button>
              <Button
                disabled={formStatus !== FORM_READY && formStatus !== FORM_FAILED}
                loading={formStatus === FORM_SENDING}
                leftSection={(
                  <FontAwesomeIcon
                    icon={faSignInAlt}
                    fixedWidth
                  />
                )}
                onClick={handleFormSubmit}
                type="submit"
              >
                <FormattedMessage
                  id="sign_in"
                  defaultMessage="Se connecter"
                />
              </Button>
            </Group>
          </Box>
        </Stack>
      </form>
    </Modal>
  )
}

export default AuthModal
