import { createContext, useContext } from 'react'

export type CurrentModule = {
  currentModule?: string
  isPanelOpen: boolean
  setCurrentModule: (module?: string) => void
  setIsPanelOpen: (open: boolean) => void
  toggleModule: (module: string) => void
  togglePanel: () => void
}

/**
 * Contexte du module actuel.
 */
export const CurrentModuleContext = createContext<CurrentModule>(null)

/**
 * Retourne le contexte du module actuel.
 */
function useCurrentModuleContext () {
  return useContext(CurrentModuleContext)
}

export default useCurrentModuleContext
