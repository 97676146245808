import { Stack, StackProps } from '@mantine/core'
import { PropsWithChildren, ReactElement } from 'react'

/**
 * Barre d'outils de la carte.
 */
function MapToolbar (props: PropsWithChildren & StackProps): ReactElement {
  const {
    children,
    ...others
  } = props
  return (
    <Stack
      gap={5}
      className="map-toolbar"
      pos="absolute"
      top={0}
      p="sm"
      {...others}
    >
      {children}
    </Stack>
  )
}

export default MapToolbar
