import { parseField } from '@jalik/form-parser'
import { ChangeEvent } from 'react'

/**
 * Retourne le nom et la valeur du champ modifié dans une callback.
 * @param callback
 */
export function handleFieldChange (callback: (name: string, value: unknown) => void) {
  return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { currentTarget } = event
    callback(currentTarget.name, parseField(currentTarget, { trim: false }))
  }
}

/**
 * Passe le nom du champ avec la valeur changée.
 * @param name
 * @param callback
 */
export function handleValueChange (name: string, callback: (name: string, value: any) => void) {
  return (nextValue: any) => {
    callback(name, nextValue)
  }
}
