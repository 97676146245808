import 'ol/ol.css'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import { createRoot } from 'react-dom/client'
import '../css/tefenua-icons.css'
import '../scss/main.scss'
import Package from '../../../package.json'
import './lib/helpers'
import App from './components/App'
import { logger } from './lib/logger'
import './lib/polyfill'

// Affiche la version de l'application dans la console.
logger.info(`${Package.title} v${Package.version}`)

// Injecte l'application React dans le DOM.
const root = createRoot(document.getElementById('root'))
root.render(<App />)
