import { Modal, ModalProps } from '@mantine/core'
import { lazy, ReactElement, Suspense } from 'react'
import { useIntl } from 'react-intl'
import LoadingModal from '../../LoadingModal'

const About = lazy(() => import('./About'))

function AboutModal (props: ModalProps): ReactElement {
  const {
    onClose,
    opened,
    ...others
  } = props

  const intl = useIntl()

  return (
    <Suspense fallback={<LoadingModal />}>
      <Modal
        centered
        size="xl"
        title={intl.formatMessage({
          id: 'about',
          defaultMessage: 'À propos'
        })}
        {...others}
        onClose={onClose}
        opened={opened}
      >
        <About />
      </Modal>
    </Suspense>
  )
}

export default AboutModal
