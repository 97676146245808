import { createContext, useContext } from 'react'

export type TimeLineContext_ = {
  setTime (time: string): void
  time: string
}

/**
 * Contexte de temps.
 */
export const TimeLineContext = createContext<TimeLineContext_>(null)

/**
 * Retourne le contexte de temps.
 */
function useTimeLineContext () {
  return useContext(TimeLineContext)
}

export default useTimeLineContext
