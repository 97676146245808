import { useEffect } from 'react'
import OlMap from 'ol/Map'
import { Interaction } from 'ol/interaction'

/**
 * Hook permettant d'ajouter une interaction à la carte.
 * @param map
 * @param interaction
 */
function useMapInteraction (map: OlMap, interaction: Interaction) {
  useEffect(() => {
    if (map && interaction) {
      map.addInteraction(interaction)
    }
    return () => {
      if (map && interaction) {
        map.removeInteraction(interaction)
      }
    }
  }, [map, interaction])
}

export default useMapInteraction
