import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import usePersistentState from '../../../hooks/usePersistentState'
import {
  getDefaultPreferences,
  Preferences,
  PreferencesContext,
  PreferencesContext_
} from './usePreferencesContext'
import PreferencesModal from './PreferencesModal'

export const LS_PREFERENCE_ID = 'tf.settings'

/**
 * Fournisseur du contexte des préférences utilisateur.
 * @param props
 */
function PreferencesContextProvider (props: PropsWithChildren) {
  const { children } = props
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState<boolean>(false)

  // Charge les préférences de l'utilisateur depuis le stockage local du
  // navigateur.
  const [preferences, setPreferences] = usePersistentState<Preferences>(
    LS_PREFERENCE_ID,
    getDefaultPreferences
  )

  const setPreference = useCallback((name: string, value: unknown) => {
    setPreferences((s) => ({ ...s, [name]: value }))
  }, [setPreferences])

  const togglePreferencesModal = useCallback(() => {
    setIsPreferencesModalOpen((s) => !s)
  }, [])

  const providerContext = useMemo<PreferencesContext_>(
    () => ({
      isPreferencesModalOpen,
      preferences,
      setPreference,
      setPreferences,
      togglePreferencesModal
    }),
    [
      isPreferencesModalOpen,
      preferences,
      setPreference,
      setPreferences,
      togglePreferencesModal
    ]
  )

  return (
    <PreferencesContext.Provider value={providerContext}>
      {children}

      <PreferencesModal
        onClose={togglePreferencesModal}
        opened={isPreferencesModalOpen}
      />
    </PreferencesContext.Provider>
  )
}

export default PreferencesContextProvider
