import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import { BTN_FULLSCREEN_ID } from '../../../enums/UI'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'

declare global {
  // Extend native declaration so Typescript
  // does not complain about missing functions.
  interface Document {
    mozCancelFullScreen (): Promise<void>
    mozFullScreen: boolean
    mozFullScreenElement (): Element
    msExitFullscreen (): Promise<void>
    msFullscreen: boolean
    msFullscreenElement (): Element
    webkitExitFullscreen (): Promise<void>
    webkitFullscreen: boolean
    webkitFullscreenElement (): Element
  }

  interface HTMLElement {
    mozRequestFullScreen (): Promise<void>
    msRequestFullscreen (): Promise<void>
    webkitRequestFullscreen (): Promise<void>
  }
}

/**
 * Bascule l'élément en plein écran
 * @param element
 */
function enterFullScreen (element: HTMLElement) {
  if (element.requestFullscreen) {
    return element.requestFullscreen()
  }
  if (element.webkitRequestFullscreen) {
    return element.webkitRequestFullscreen()
  }
  if (element.mozRequestFullScreen) {
    return element.mozRequestFullScreen()
  }
  if (element.msRequestFullscreen) {
    return element.msRequestFullscreen()
  }
  return null
}

/**
 * Quitte le mode plein écran
 */
function exitFullScreen () {
  if (document.exitFullscreen) {
    return document.exitFullscreen()
  }
  if (document.webkitExitFullscreen) {
    return document.webkitExitFullscreen()
  }
  if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen()
  }
  if (document.msExitFullscreen) {
    return document.msExitFullscreen()
  }
  return null
}

/**
 * Vérifie si le mode plein écran est actif
 */
function isFullScreen (): boolean {
  return document.fullscreen ||
    document.webkitFullscreen ||
    document.mozFullScreen ||
    document.msFullscreen ||
    document.fullscreenElement != null ||
    document.webkitFullscreenElement != null ||
    document.mozFullScreenElement != null ||
    document.msFullscreenElement != null
}

/**
 * Bouton permettant de passer ou de quitter le plein écran.
 */
function MapFullScreenButton () {
  const [fullScreen, setFullScreen] = useState<boolean>(isFullScreen())

  /**
   * Bascule le mode plein écran.
   */
  const toggleFullScreen = useCallback(() => {
    setFullScreen((v) => !v)
    // Note: il est impossible de déclencher le plein écran dans useEffect(),
    // car le navigateur l'autorise uniquement dans le gestionnaire
    // d'événements de l'utilisateur.
    if (!fullScreen) enterFullScreen(document.getElementById('body'))
    else exitFullScreen()
  }, [fullScreen])

  // fixme synchroniser "fullscreen" lorsque l'on entre ou quitte le plein écran
  //  autrement qu'en utilisant le bouton

  return (
    <ConfigurableUIComponent
      id={BTN_FULLSCREEN_ID}
      showByDefault
    >
      <MapToolbarButton
        active={fullScreen}
        onClick={toggleFullScreen}
        title={fullScreen ? 'Quitter le plein écran' : 'Passer en plein écran'}
      >
        <FontAwesomeIcon
          icon={faExpand}
          fixedWidth
        />
      </MapToolbarButton>
    </ConfigurableUIComponent>
  )
}

export default MapFullScreenButton
