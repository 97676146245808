import { ReactElement } from 'react'
import { Button, Center, Group, Modal, ModalProps } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export type ConfirmModalProps = {
  onConfirm (): void
}

/**
 * Modale de demande de confirmation.
 * @param props
 */
function ConfirmModal (props: ModalProps & ConfirmModalProps): ReactElement {
  const {
    children,
    onClose,
    onConfirm,
    title,
    ...others
  } = props

  return (
    <Modal
      centered
      size="sm"
      closeOnClickOutside={false}
      withCloseButton={false}
      {...others}
      onClose={onClose}
      title={title}
    >
      {children}

      <Center mt="md">
        <Group>
          <Button
            onClick={onClose}
            type="button"
            variant="light"
          >
            <FormattedMessage
              id="cancel"
              defaultMessage="Annuler"
            />
          </Button>
          <Button
            onClick={onConfirm}
            type="button"
          >
            <FormattedMessage
              id="confirm"
              defaultMessage="Confirmer"
            />
          </Button>
        </Group>
      </Center>
    </Modal>
  )
}

export default ConfirmModal
