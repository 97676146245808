import { createContext, SetStateAction, useContext } from 'react'
import { COORD_FORMAT_DD } from '../../../enums/CoordinateFormats'
import {
  PREF_COORD_GEO_FORMAT,
  PREF_RESTORE_MAP_VIEW,
  PREF_SHOW_COORD_DFCI,
  PREF_SHOW_COORD_GEO,
  PREF_SHOW_COORD_UTM,
  PREF_SHOW_MAP_SCALE
} from '../../../enums/Preferences'
import { Coordinate } from 'ol/coordinate'

export type Preferences = {
  coord_geo_format: 'dd' | 'dms' | 'dm'
  show_coord_dfci: boolean
  show_coord_geo: boolean
  show_coord_utm: boolean
  show_map_scale: boolean
  restore_map_view: boolean
  mapView?: {
    center?: Coordinate
    zoom?: number
  }
}

export type PreferencesContext_ = {
  isPreferencesModalOpen: boolean
  preferences: Preferences
  setPreference (name: string, value: unknown): void
  setPreferences (values: SetStateAction<Preferences>): void
  togglePreferencesModal (): void
}

/**
 * Retourne les préférences par défaut.
 */
export function getDefaultPreferences (): Preferences {
  return {
    [PREF_COORD_GEO_FORMAT]: COORD_FORMAT_DD,
    [PREF_SHOW_COORD_DFCI]: false,
    [PREF_SHOW_COORD_GEO]: true,
    [PREF_SHOW_COORD_UTM]: false,
    [PREF_SHOW_MAP_SCALE]: false,
    [PREF_RESTORE_MAP_VIEW]: false
  }
}

/**
 * Contexte des préférences.
 */
export const PreferencesContext = createContext<PreferencesContext_>(null)

/**
 * Retourne le contexte des préférences.
 */
function usePreferencesContext () {
  return useContext(PreferencesContext)
}

export default usePreferencesContext
