import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { logger } from '../lib/logger'

function getLocalStorageItem (key: string) {
  const item = window.localStorage.getItem(key)

  try {
    return typeof item === 'string' ? JSON.parse(item) : item
  } catch (error) {
    // eslint-disable-next-line no-console
    logger.error(error.message)
    return null
  }
}

function isLocalStorageSupported () {
  return typeof window !== 'undefined' && typeof window.localStorage !==
    'undefined'
}

function setLocalStorageItem (key: string, value: unknown) {
  if (value != null) {
    window.localStorage.setItem(
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value
    )
  } else {
    window.localStorage.removeItem(key)
  }
}

function usePersistentState<S> (id: string, initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState<S>(() => {
    if (isLocalStorageSupported()) {
      const item = getLocalStorageItem(id)

      if (item != null) {
        return item
      }
    }
    return typeof initialState === 'function'
      // @ts-ignore
      ? initialState()
      : initialState
  })

  useEffect(() => {
    setLocalStorageItem(id, value)
  }, [id, value])

  return [value, setValue]
}

export default usePersistentState
