/**
 * UTM zones are grouped, and assigned to one of a group of 6
 * sets.
 *
 * {int} @private
 */
const NUM_100K_SETS = 6

/**
 * The column letters (for easting) of the lower left value, per
 * set.
 *
 * {string} @private
 */
const SET_ORIGIN_COLUMN_LETTERS = 'AJSAJS'

/**
 * The row letters (for northing) of the lower left value, per
 * set.
 *
 * {string} @private
 */
const SET_ORIGIN_ROW_LETTERS = 'AFAFAF'

const A = 65 // A
const I = 73 // I
const O = 79 // O
const V = 86 // V
const Z = 90 // Z

/**
 * Conversion of lat/lon to DFCI.
 *
 * @param {object} ll Object literal with lat and lon properties on a
 *     WGS84 ellipsoid.
 * @param {int} accuracy Accuracy in digits (4 for 1 km, 3 for 2 km, 2 for
 *      20 km, 1 for 100 km). Optional, default is 4.
 * @return {string} the DFCI string for the given location and accuracy.
 */
export function forwardDFCI (ll, accuracy) {
  accuracy = accuracy || 4 // default accuracy 1km
  return encode(LLtoUTM({
    lat: ll[1],
    lon: ll[0]
  }), accuracy)
}

/**
 * Conversion of MGRS to lat/lon.
 *
 * @param {string} mgrs MGRS string.
 * @return {array} An array with left (longitude), bottom (latitude), right
 *     (longitude) and top (latitude) values in WGS84, representing the
 *     bounding box for the provided MGRS reference.
 */
export function inverseDFCI (mgrs) {
  const bbox = UTMtoLL(decode(mgrs.toUpperCase()))
  if (bbox.lat && bbox.lon) {
    return [bbox.lon, bbox.lat, bbox.lon, bbox.lat]
  }
  return [bbox.left, bbox.bottom, bbox.right, bbox.top]
}

export function toPoint (mgrs) {
  const bbox = UTMtoLL(decode(mgrs.toUpperCase()))
  if (bbox.lat && bbox.lon) {
    return [bbox.lon, bbox.lat]
  }
  return [(bbox.left + bbox.right) / 2, (bbox.top + bbox.bottom) / 2]
}

/**
 * Conversion from degrees to radians.
 *
 * @private
 * @param {number} deg the angle in degrees.
 * @return {number} the angle in radians.
 */
function degToRad (deg) {
  return (deg * (Math.PI / 180.0))
}

/**
 * Conversion from radians to degrees.
 *
 * @private
 * @param {number} rad the angle in radians.
 * @return {number} the angle in degrees.
 */
function radToDeg180 (rad) {
  return (180.0 * (rad / Math.PI))
}

/**
 * Converts a set of Longitude and Latitude co-ordinates to UTM
 * using the WGS84 ellipsoid.
 *
 * @private
 * @param {object} ll Object literal with lat and lon properties
 *     representing the WGS84 coordinate to be converted.
 * @return {object} Object literal containing the UTM value with easting,
 *     northing, zoneNumber and zoneLetter properties, and an optional
 *     accuracy property in digits. Returns null if the conversion failed.
 */
function LLtoUTM (ll) {
  const Lat = ll.lat
  const Long = ll.lon
  const a = 6378137.0 // ellip.radius;
  const eccSquared = 0.00669438 // ellip.eccsq;
  const k0 = 0.9996
  const LatRad = degToRad(Lat)
  const LongRad = degToRad(Long)
  let ZoneNumber = Math.floor((Long + 180) / 6) + 1

  // Make sure the longitude 180.00 is in Zone 60
  if (Long === 180) {
    ZoneNumber = 60
  }

  // Special zone for Norway
  if (Lat >= 56.0 && Lat < 64.0 && Long >= 3.0 && Long < 12.0) {
    ZoneNumber = 32
  }

  // Special zones for Svalbard
  if (Lat >= 72.0 && Lat < 84.0) {
    if (Long >= 0.0 && Long < 9.0) {
      ZoneNumber = 31
    } else if (Long >= 9.0 && Long < 21.0) {
      ZoneNumber = 33
    } else if (Long >= 21.0 && Long < 33.0) {
      ZoneNumber = 35
    } else if (Long >= 33.0 && Long < 42.0) {
      ZoneNumber = 37
    }
  }

  const LongOrigin = (ZoneNumber - 1) * 6 - 180 + 3 // +3 puts origin
  // in middle of
  // zone
  const LongOriginRad = degToRad(LongOrigin)

  const eccPrimeSquared = (eccSquared) / (1 - eccSquared)

  const N = a / Math.sqrt(1 - eccSquared * Math.sin(LatRad) * Math.sin(LatRad))
  const T = Math.tan(LatRad) * Math.tan(LatRad)
  const C = eccPrimeSquared * Math.cos(LatRad) * Math.cos(LatRad)
  const A = Math.cos(LatRad) * (LongRad - LongOriginRad)

  const M = a * ((1 - eccSquared / 4 - 3 * eccSquared * eccSquared / 64 - 5 *
    eccSquared * eccSquared * eccSquared / 256) * LatRad - (3 * eccSquared / 8 +
    3 * eccSquared * eccSquared / 32 + 45 * eccSquared * eccSquared *
    eccSquared / 1024) * Math.sin(2 * LatRad) + (15 * eccSquared * eccSquared /
    256 + 45 * eccSquared * eccSquared * eccSquared / 1024) * Math.sin(4 *
    LatRad) - (35 * eccSquared * eccSquared * eccSquared / 3072) * Math.sin(6 *
    LatRad))

  const UTMEasting = (k0 * N * (A + (1 - T + C) * A * A * A / 6.0 + (5 - 18 * T +
      T * T + 72 * C - 58 * eccPrimeSquared) * A * A * A * A * A / 120.0) +
    500000.0)

  let UTMNorthing = (k0 * (M + N * Math.tan(LatRad) * (A * A / 2 + (5 - T + 9 *
    C + 4 * C * C) * A * A * A * A / 24.0 + (61 - 58 * T + T * T + 600 * C -
    330 * eccPrimeSquared) * A * A * A * A * A * A / 720.0)))
  if (Lat < 0.0) {
    UTMNorthing += 10000000.0 // 10000000 meter offset for
    // southern hemisphere
  }

  return {
    northing: Math.round(UTMNorthing),
    easting: Math.round(UTMEasting),
    zoneNumber: ZoneNumber,
    zoneLetter: getLetterDesignator(Lat)
  }
}

/**
 * Converts UTM coords to lat/long, using the WGS84 ellipsoid. This is a
 * convenience class where the Zone can be specified as a single string
 * eg."60N" which is then broken down into the ZoneNumber and ZoneLetter.
 *
 * @private
 * @param {object} utm An object literal with northing, easting, zoneNumber
 *     and zoneLetter properties. If an optional accuracy property is
 *     provided (in meters), a bounding box will be returned instead of
 *     latitude and longitude.
 * @return {object} An object literal containing either lat and lon values
 *     (if no accuracy was provided), or top, right, bottom and left values
 *     for the bounding box calculated according to the provided accuracy.
 *     Returns null if the conversion failed.
 */
function UTMtoLL (utm) {
  const UTMNorthing = utm.northing
  const UTMEasting = utm.easting
  const { zoneLetter } = utm
  const { zoneNumber } = utm
  // check the ZoneNummber is valid
  if (zoneNumber < 0 || zoneNumber > 60) {
    return null
  }

  const k0 = 0.9996
  const a = 6378137.0 // ellip.radius;
  const eccSquared = 0.00669438 // ellip.eccsq;
  const e1 = (1 - Math.sqrt(1 - eccSquared)) / (1 + Math.sqrt(1 - eccSquared))

  // remove 500,000 meter offset for longitude
  const x = UTMEasting - 500000.0
  let y = UTMNorthing

  // We must know somehow if we are in the Northern or Southern
  // hemisphere, this is the only time we use the letter So even
  // if the Zone letter isn't exactly correct it should indicate
  // the hemisphere correctly
  if (zoneLetter < 'N') {
    y -= 10000000.0 // remove 10,000,000 meter offset used
    // for southern hemisphere
  }

  // There are 60 zones with zone 1 being at West -180 to -174
  const LongOrigin = (zoneNumber - 1) * 6 - 180 + 3 // +3 puts origin
  // in middle of
  // zone

  const eccPrimeSquared = (eccSquared) / (1 - eccSquared)

  const M = y / k0
  const mu = M / (a * (1 - eccSquared / 4 - 3 * eccSquared * eccSquared / 64 - 5 *
    eccSquared * eccSquared * eccSquared / 256))

  const phi1Rad = mu + (3 * e1 / 2 - 27 * e1 * e1 * e1 / 32) * Math.sin(2 * mu) +
    (21 *
      e1 * e1 / 16 - 55 * e1 * e1 * e1 * e1 / 32) * Math.sin(4 * mu) + (151 * e1 *
      e1 * e1 / 96) * Math.sin(6 * mu)
  // double phi1 = ProjMath.radToDeg180(phi1Rad);

  const N1 = a / Math.sqrt(1 - eccSquared * Math.sin(phi1Rad) *
    Math.sin(phi1Rad))
  const T1 = Math.tan(phi1Rad) * Math.tan(phi1Rad)
  const C1 = eccPrimeSquared * Math.cos(phi1Rad) * Math.cos(phi1Rad)
  const R1 = a * (1 - eccSquared) / Math.pow(1 - eccSquared * Math.sin(phi1Rad) *
    Math.sin(phi1Rad), 1.5)
  const D = x / (N1 * k0)

  let lat = phi1Rad - (N1 * Math.tan(phi1Rad) / R1) * (D * D / 2 - (5 + 3 * T1 +
      10 * C1 - 4 * C1 * C1 - 9 * eccPrimeSquared) * D * D * D * D / 24 + (61 +
      90 * T1 + 298 * C1 + 45 * T1 * T1 - 252 * eccPrimeSquared - 3 * C1 * C1) *
    D * D * D * D * D * D / 720)
  lat = radToDeg180(lat)

  let lon = (D - (1 + 2 * T1 + C1) * D * D * D / 6 + (5 - 2 * C1 + 28 * T1 - 3 *
      C1 * C1 + 8 * eccPrimeSquared + 24 * T1 * T1) * D * D * D * D * D / 120) /
    Math.cos(phi1Rad)
  lon = LongOrigin + radToDeg180(lon)

  let result
  if (utm.accuracy) {
    const topRight = UTMtoLL({
      northing: utm.northing + utm.accuracy,
      easting: utm.easting + utm.accuracy,
      zoneLetter: utm.zoneLetter,
      zoneNumber: utm.zoneNumber
    })
    result = {
      top: topRight.lat,
      right: topRight.lon,
      bottom: lat,
      left: lon
    }
  } else {
    result = {
      lat,
      lon
    }
  }
  return result
}

/**
 * Calculates the MGRS letter designator for the given latitude.
 *
 * @private
 * @param {number} lat The latitude in WGS84 to get the letter designator
 *     for.
 * @return {char} The letter designator.
 */
function getLetterDesignator (lat) {
  // This is here as an error flag to show that the Latitude is
  // outside MGRS limits
  let LetterDesignator = 'Z'

  if ((lat <= 84) && (lat >= 72)) {
    LetterDesignator = 'X'
  } else if ((lat < 72) && (lat >= 64)) {
    LetterDesignator = 'W'
  } else if ((lat < 64) && (lat >= 56)) {
    LetterDesignator = 'V'
  } else if ((lat < 56) && (lat >= 48)) {
    LetterDesignator = 'U'
  } else if ((lat < 48) && (lat >= 40)) {
    LetterDesignator = 'T'
  } else if ((lat < 40) && (lat >= 32)) {
    LetterDesignator = 'S'
  } else if ((lat < 32) && (lat >= 24)) {
    LetterDesignator = 'R'
  } else if ((lat < 24) && (lat >= 16)) {
    LetterDesignator = 'Q'
  } else if ((lat < 16) && (lat >= 8)) {
    LetterDesignator = 'P'
  } else if ((lat < 8) && (lat >= 0)) {
    LetterDesignator = 'N'
  } else if ((lat < 0) && (lat >= -8)) {
    LetterDesignator = 'M'
  } else if ((lat < -8) && (lat >= -16)) {
    LetterDesignator = 'L'
  } else if ((lat < -16) && (lat >= -24)) {
    LetterDesignator = 'K'
  } else if ((lat < -24) && (lat >= -32)) {
    LetterDesignator = 'J'
  } else if ((lat < -32) && (lat >= -40)) {
    LetterDesignator = 'H'
  } else if ((lat < -40) && (lat >= -48)) {
    LetterDesignator = 'G'
  } else if ((lat < -48) && (lat >= -56)) {
    LetterDesignator = 'F'
  } else if ((lat < -56) && (lat >= -64)) {
    LetterDesignator = 'E'
  } else if ((lat < -64) && (lat >= -72)) {
    LetterDesignator = 'D'
  } else if ((lat < -72) && (lat >= -80)) {
    LetterDesignator = 'C'
  }
  return LetterDesignator
}

/**
 * Encodes a UTM location as DFCI string.
 *
 * @private
 * @param {object} utm An object literal with easting, northing,
 *     zoneLetter, zoneNumber
 * @param {number} accuracy Accuracy in digits (1-5).
 * @return {string} DFCI string for the given UTM location.
 */
function encode (utm, accuracy) {
  let dfci = utm.zoneNumber + utm.zoneLetter

  if (accuracy >= 1) {
    dfci += get100kID(utm.easting, utm.northing, utm.zoneNumber)
  }
  if (accuracy >= 2) {
    dfci += get20kmID(utm.easting, utm.northing)
  }
  if (accuracy >= 3) {
    dfci += get2kmID(utm.easting, utm.northing)
  }
  if (accuracy >= 4) {
    dfci = `${dfci}.${get1kmBox(utm.easting, utm.northing)}`
  }
  return dfci
}

/**
 * Get the two -digit 2km designator for a given UTM easting,
 * northing and zone number value.
 *
 * @private
 * @param {number} easting
 * @param {number} northing
 * @return the two digit 2km designator for the given UTM location.
 */
function get1kmBox (easting, northing) {
  const seasting = `${easting}`
  const snorthing = `${northing}`
  let square = 0

  const set2kmeasting = parseInt(seasting.substr(seasting.length - 4, 2), 10)
  const set2kmnorthing = parseInt(
    snorthing.substr(snorthing.length - 4, 2),
    10
  )
  const set2kmColumn = set2kmeasting - Math.floor(set2kmeasting / 20) * 20
  const set2kmRow = set2kmnorthing - Math.floor(set2kmnorthing / 20) * 20
  if (set2kmColumn <= 9 && set2kmRow <= 9) {
    square = 4
  }
  if (set2kmColumn > 9 && set2kmRow <= 9) {
    square = 3
  }
  if (set2kmColumn > 9 && set2kmRow > 9) {
    square = 2
  }
  if (set2kmColumn <= 9 && set2kmRow > 9) {
    square = 1
  }
  if (set2kmColumn > 4 && set2kmColumn <= 14 && set2kmRow > 4 && set2kmRow <=
    14) {
    square = 5
  }
  return `${square}`
}

/**
 * Get the two digits 20km designator for a given UTM easting,
 * northing and zone number value.
 *
 * @private
 * @param {number} easting
 * @param {number} northing
 * @return the two digit 20km designator for the given UTM location.
 */
function get20kmID (easting, northing) {
  const seasting = `${easting}`
  const snorthing = `${northing}`

  const set20kmeasting = parseInt(seasting.substr(seasting.length - 5, 2), 10)
  const set20kmnorthing = parseInt(
    snorthing.substr(snorthing.length - 5, 2),
    10
  )
  const set20kmColumn = Math.floor(set20kmeasting / 20) * 2
  const set20kmRow = Math.floor(set20kmnorthing / 20) * 2

  return `${set20kmColumn}${set20kmRow}`
}

/**
 * Get the two digits 2km designator for a given UTM easting,
 * northing and zone number value.
 *
 * @private
 * @param {number} easting
 * @param {number} northing
 * @return the two digit 20km designator for the given UTM location.
 */
function get2kmID (easting, northing) {
  const seasting = `${easting}`
  const snorthing = `${northing}`
  const setLetter = 'ABCDEFGHKL'

  const set20kmeasting = parseInt(seasting.substr(seasting.length - 5, 2), 10)
  const set20kmnorthing = parseInt(
    snorthing.substr(snorthing.length - 5, 2),
    10
  )
  const set20kmColumn = Math.floor(set20kmeasting / 20) * 2
  const set20kmRow = Math.floor(set20kmnorthing / 20) * 2

  const set2kmColumn = Math.floor((set20kmeasting - set20kmColumn * 10) / 2)
  const set2kmRow = Math.floor((set20kmnorthing - set20kmRow * 10) / 2)
  return `${setLetter.charAt(set2kmColumn)}${set2kmRow}`
}

/**
 * Get the two letter 100k designator for a given UTM easting,
 * northing and zone number value.
 *
 * @private
 * @param {number} easting
 * @param {number} northing
 * @param {number} zoneNumber
 * @return the two letter 100k designator for the given UTM location.
 */
function get100kID (easting, northing, zoneNumber) {
  const setParm = get100kSetForZone(zoneNumber)
  const setColumn = Math.floor(easting / 100000)
  const setRow = Math.floor(northing / 100000) % 20
  return getLetter100kID(setColumn, setRow, setParm)
}

/**
 * Given a UTM zone number, figure out the MGRS 100K set it is in.
 *
 * @private
 * @param {number} i An UTM zone number.
 * @return {number} the 100k set the UTM zone is in.
 */
function get100kSetForZone (i) {
  let setParm = i % NUM_100K_SETS
  if (setParm === 0) {
    setParm = NUM_100K_SETS
  }

  return setParm
}

/**
 * Get the two-letter MGRS 100k designator given information
 * translated from the UTM northing, easting and zone number.
 *
 * @private
 * @param {number} column the column index as it relates to the MGRS
 *        100k set spreadsheet, created from the UTM easting.
 *        Values are 1-8.
 * @param {number} row the row index as it relates to the MGRS 100k set
 *        spreadsheet, created from the UTM northing value. Values
 *        are from 0-19.
 * @param {number} parm the set block, as it relates to the MGRS 100k set
 *        spreadsheet, created from the UTM zone. Values are from
 *        1-60.
 * @return two letter MGRS 100k code.
 */
function getLetter100kID (column, row, parm) {
  // colOrigin and rowOrigin are the letters at the origin of the set
  const index = parm - 1
  const colOrigin = SET_ORIGIN_COLUMN_LETTERS.charCodeAt(index)
  const rowOrigin = SET_ORIGIN_ROW_LETTERS.charCodeAt(index)

  // colInt and rowInt are the letters to build to return
  let colInt = colOrigin + column - 1
  let rowInt = rowOrigin + row
  let rollover = false

  if (colInt > Z) {
    colInt = colInt - Z + A - 1
    rollover = true
  }

  if (colInt === I || (colOrigin < I && colInt > I) || ((colInt > I || colOrigin <
    I) && rollover)) {
    colInt += 1
  }

  if (colInt === O || (colOrigin < O && colInt > O) || ((colInt > O || colOrigin <
    O) && rollover)) {
    colInt += 1

    if (colInt === I) {
      colInt += 1
    }
  }

  if (colInt > Z) {
    colInt = colInt - Z + A - 1
  }

  if (rowInt > V) {
    rowInt = rowInt - V + A - 1
    rollover = true
  } else {
    rollover = false
  }

  if (((rowInt === I) || ((rowOrigin < I) && (rowInt > I))) || (((rowInt > I) ||
    (rowOrigin < I)) && rollover)) {
    rowInt += 1
  }

  if (((rowInt === O) || ((rowOrigin < O) && (rowInt > O))) || (((rowInt > O) ||
    (rowOrigin < O)) && rollover)) {
    rowInt += 1

    if (rowInt === I) {
      rowInt += 1
    }
  }

  if (rowInt > V) {
    rowInt = rowInt - V + A - 1
  }

  return String.fromCharCode(colInt) + String.fromCharCode(rowInt)
}

/**
 * Decode the UTM parameters from a MGRS string.
 *
 * @private
 * @param {string} mgrsString an UPPERCASE coordinate string is expected.
 * @return {object} An object literal with easting, northing, zoneLetter,
 *     zoneNumber and accuracy (in meters) properties.
 */
function decode (mgrsString) {
  if (mgrsString && mgrsString.length === 0) {
    throw new Error('MGRSPoint coverting from nothing')
  }

  const { length } = mgrsString

  let hunK = null
  let sb = ''
  let testChar
  let i = 0

  // get Zone number
  while (!(/[A-Z]/).test(testChar = mgrsString.charAt(i))) {
    if (i >= 2) {
      throw new Error(`MGRSPoint bad conversion from: ${mgrsString}`)
    }
    sb += testChar
    i += 1
  }

  const zoneNumber = parseInt(sb, 10)

  if (i === 0 || i + 3 > length) {
    // A good MGRS string has to be 4-5 digits long,
    // ##AAA/#AAA at least.
    throw new Error(`MGRSPoint bad conversion from: ${mgrsString}`)
  }

  const zoneLetter = mgrsString.charAt(i)
  i += 1

  // Should we check the zone letter here? Why not.
  if (zoneLetter <= 'A' || zoneLetter === 'B' || zoneLetter === 'Y' ||
    zoneLetter >= 'Z' || zoneLetter === 'I' || zoneLetter === 'O') {
    throw new Error(`MGRSPoint zone letter ${zoneLetter} not handled: ${mgrsString}`)
  }

  hunK = mgrsString.substring(i, i += 2)

  const set = get100kSetForZone(zoneNumber)
  const east100k = getEastingFromChar(hunK.charAt(0), set)
  let north100k = getNorthingFromChar(hunK.charAt(1), set)

  // We have a bug where the northing may be 2000000 too low.
  // How
  // do we know when to roll over?

  while (north100k < getMinNorthing(zoneLetter)) {
    north100k += 2000000
  }

  // calculate the char index for easting/northing separator
  const remainder = length - i

  if (remainder % 2 !== 0) {
    throw new Error(`DFCI has to have an even number \nof charactes after the zone letter and two 100km letters: ${mgrsString}`)
  }

  const sep = remainder / 2 + 1
  let accuracyBonus
  let easting
  let
    northing

  if (sep >= 1) {
    easting = east100k
    northing = north100k
    accuracyBonus = 100000.0
  }

  if (sep >= 2) {
    var zone20km = mgrsString.substring(i, i += 2)
    if ('02468'.indexOf(zone20km[0]) < 0 || '02468'.indexOf(zone20km[1]) < 0) {
      throw new Error(
        'DFCI 20km square should be 0, 2, 4, 6 or 8 eastward and northward.')
    }
    easting += parseInt(zone20km[0], 10) * 10000
    northing += parseInt(zone20km[1], 10) * 10000
    accuracyBonus = 20000.0
  }

  if (sep >= 3) {
    const zone2km = mgrsString.substring(i, i += 2)
    const setLetter2km = 'ABCDEFGHKL'
    if ('0123456789'.indexOf(zone2km[1]) < 0) {
      throw new Error('DFCI 2km square should be 0 to 9 northward.')
    }
    if (setLetter2km.indexOf(zone2km[0]) < 0) {
      throw new Error(`DFCI 2km square should be a letter in '${setLetter2km}' eastward.${zone20km},${mgrsString},${i}`)
    }
    easting += setLetter2km.indexOf(zone2km[0]) * 2000
    northing += parseInt(zone2km[1], 10) * 2000
    accuracyBonus = 2000.0
  }

  if (sep >= 4) {
    const zone1km = mgrsString.substring(i, i += 2)
    if (zone1km[0] !== '.') {
      throw new Error('Last but one character of DFCI string should be a point.')
    }
    if ('12345'.indexOf(zone1km[1]) < 0) {
      throw new Error('Last DFCI character should be 1, 2, 3, 4 or 5.')
    }

    accuracyBonus = 1000.0
    if (zone1km[1] === '1') {
      easting += 0
      northing += 1000
    }
    if (zone1km[1] === '2') {
      easting += 1000
      northing += 1000
    }
    if (zone1km[1] === '3') {
      easting += 1000
      northing += 0
    }
    if (zone1km[1] === '4') {
      easting += 0
      northing += 0
    }
    if (zone1km[1] === '5') {
      easting += 500
      northing += 500
    }
  }

  return {
    easting,
    northing,
    zoneLetter,
    zoneNumber,
    accuracy: accuracyBonus
  }
}

/**
 * Given the first letter from a two-letter MGRS 100k zone, and given the
 * MGRS table set for the zone number, figure out the easting value that
 * should be added to the other, secondary easting value.
 *
 * @private
 * @param {char} e The first letter from a two-letter MGRS 100´k zone.
 * @param {number} set The MGRS table set for the zone number.
 * @return {number} The easting value for the given letter and set.
 */
function getEastingFromChar (e, set) {
  // colOrigin is the letter at the origin of the set for the
  // column
  let curCol = SET_ORIGIN_COLUMN_LETTERS.charCodeAt(set - 1)
  let eastingValue = 100000.0
  let rewindMarker = false

  while (curCol !== e.charCodeAt(0)) {
    curCol += 1
    if (curCol === I) {
      curCol += 1
    }
    if (curCol === O) {
      curCol += 1
    }
    if (curCol > Z) {
      if (rewindMarker) {
        throw new Error(`Bad character: ${e}`)
      }
      curCol = A
      rewindMarker = true
    }
    eastingValue += 100000.0
  }

  return eastingValue
}

/**
 * Given the second letter from a two-letter MGRS 100k zone, and given the
 * MGRS table set for the zone number, figure out the northing value that
 * should be added to the other, secondary northing value. You have to
 * remember that Northings are determined from the equator, and the vertical
 * cycle of letters mean a 2000000 additional northing meters. This happens
 * approx. every 18 degrees of latitude. This method does *NOT* count any
 * additional northings. You have to figure out how many 2000000 meters need
 * to be added for the zone letter of the MGRS coordinate.
 *
 * @private
 * @param {char} n Second letter of the MGRS 100k zone
 * @param {number} set The MGRS table set number, which is dependent on the
 *     UTM zone number.
 * @return {number} The northing value for the given letter and set.
 */
function getNorthingFromChar (n, set) {
  if (n > 'V') {
    throw new Error(`MGRSPoint given invalid Northing ${n}`)
  }

  // rowOrigin is the letter at the origin of the set for the
  // column
  let curRow = SET_ORIGIN_ROW_LETTERS.charCodeAt(set - 1)
  let northingValue = 0.0
  let rewindMarker = false

  while (curRow !== n.charCodeAt(0)) {
    curRow += 1
    if (curRow === I) {
      curRow += 1
    }
    if (curRow === O) {
      curRow += 1
    }
    // fixing a bug making whole application hang in this loop
    // when 'n' is a wrong character
    if (curRow > V) {
      if (rewindMarker) { // making sure that this loop ends
        throw new Error(`Bad character: ${n}`)
      }
      curRow = A
      rewindMarker = true
    }
    northingValue += 100000.0
  }

  return northingValue
}

/**
 * The function getMinNorthing returns the minimum northing value of a MGRS
 * zone.
 *
 * Ported from Geotrans' c Lattitude_Band_Value structure table.
 *
 * @private
 * @param {char} zoneLetter The MGRS zone to get the min northing for.
 * @return {number}
 */
function getMinNorthing (zoneLetter) {
  let northing
  switch (zoneLetter) {
    case 'C':
      northing = 1100000.0
      break
    case 'D':
      northing = 2000000.0
      break
    case 'E':
      northing = 2800000.0
      break
    case 'F':
      northing = 3700000.0
      break
    case 'G':
      northing = 4600000.0
      break
    case 'H':
      northing = 5500000.0
      break
    case 'J':
      northing = 6400000.0
      break
    case 'K':
      northing = 7300000.0
      break
    case 'L':
      northing = 8200000.0
      break
    case 'M':
      northing = 9100000.0
      break
    case 'N':
      northing = 0.0
      break
    case 'P':
      northing = 800000.0
      break
    case 'Q':
      northing = 1700000.0
      break
    case 'R':
      northing = 2600000.0
      break
    case 'S':
      northing = 3500000.0
      break
    case 'T':
      northing = 4400000.0
      break
    case 'U':
      northing = 5300000.0
      break
    case 'V':
      northing = 6200000.0
      break
    case 'W':
      northing = 7000000.0
      break
    case 'X':
      northing = 7900000.0
      break
    default:
      northing = -1.0
  }
  if (northing >= 0.0) {
    return northing
  }
  throw new Error(`Invalid zone letter: ${zoneLetter}`)
}
