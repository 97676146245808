import { RefObject, useEffect } from 'react'
import OlMap from 'ol/Map'
import { Control } from 'ol/control'

function useMapControl (map: OlMap, control: Control, ref: RefObject<HTMLElement>) {
  useEffect(() => {
    if (ref.current) {
      control.setTarget(ref.current)
    }
    return () => {
      control.setTarget(undefined)
    }
  }, [control, ref])

  useEffect(() => {
    if (map && control) {
      map.addControl(control)
    }
    return () => {
      if (map && control) {
        map.removeControl(control)
      }
    }
  }, [map, control])
}

export default useMapControl
