import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inputValue } from '@jalik/react-form'
import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { PropsWithChildren } from 'react'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { Link } from 'react-router-dom'

export type ShareModalProps = PropsWithChildren & {
  code: string
  link: string
  onClose (): void
  onIframeSettingsClick (): void
  show: boolean
}

/**
 * Modale de partage de la carte.
 * @param props
 */
function ShareModal (props: ShareModalProps) {
  const {
    children,
    code,
    link,
    onClose,
    onIframeSettingsClick,
    show
  } = props

  const codeClipboard = useClipboard({ timeout: 3000 })
  const linkClipboard = useClipboard({ timeout: 3000 })

  return (
    <Modal
      centered
      opened={show}
      onClose={onClose}
      title="Partage de la carte"
    >
      <Stack>
        <Text c="dimmed" size="sm">
          Le partage de carte permet de générer un lien basé sur la vue
          actuelle. Ce lien inclut par défaut la coordonnée du centre de
          carte, le niveau de zoom et les couches affichées.
        </Text>

        {children}

        <Group wrap="nowrap" align="end">
          <TextInput
            label="Lien de partage"
            description="Partagez ou accédez rapidement à la carte actuelle en utilisant ce lien."
            error={linkClipboard.error?.message}
            onFocus={() => linkClipboard.copy(link)}
            readOnly
            value={inputValue(link)}
            rightSectionPointerEvents="none"
            rightSection={(
              <Text c={linkClipboard.copied ? 'green' : undefined}>
                <FontAwesomeIcon icon={linkClipboard.copied ? faCheck : faCopy} />
              </Text>
            )}
          />
          <Button
            component={Link}
            ml="auto"
            to={link}
            target="_blank"
            variant="light"
          >
            <FontAwesomeIcon
              icon={faLink}
              fixedWidth
            />
          </Button>
        </Group>

        <Group wrap="nowrap" align="end">
          <TextInput
            w="100%"
            label="Code d'intégration"
            description="Intégrez la carte sur votre site web en copiant ce code."
            error={codeClipboard.error?.message}
            name="code"
            onFocus={() => codeClipboard.copy(code)}
            readOnly
            value={inputValue(code)}
            rightSectionPointerEvents="none"
            rightSection={(
              <Text c={codeClipboard.copied ? 'green' : undefined}>
                <FontAwesomeIcon icon={codeClipboard.copied ? faCheck : faCopy} />
              </Text>
            )}
          />
          <Button
            ml="auto"
            onClick={onIframeSettingsClick}
            type="button"
            variant="light"
          >
            <FontAwesomeIcon
              icon={faCog}
              fixedWidth
            />
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}

export default ShareModal
