import { createContext, useContext } from 'react'
import { Coordinate } from 'ol/coordinate'
import { LayerInfoResults } from './info'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { InfoResult } from '../../../api/info'

export type InfoContext_ = {
  clearInfo (): void
  coordinate?: Coordinate
  info?: InfoResult
  infoLayer: VectorLayer<VectorSource>
  infoResults: LayerInfoResults[]
  isActive: boolean
  loading: boolean
  setIsActive (active: boolean): void
  toggleClearModal (): void
  toggleReportModal (): void
  toggleShareModal (): void
}

/**
 * Contexte d'info.
 */
export const InfoContext = createContext<InfoContext_>(null)

/**
 * Retourne le contexte d'info.
 */
function useInfoContext () {
  return useContext(InfoContext)
}

export default useInfoContext
