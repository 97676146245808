import { Burger, Group, Menu } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import useEventListener from '../../hooks/useEventListener'
import MapMenuButton, { MapMenuButtonProps } from './MapMenuButton'

export type MapMenuItem = MapMenuButtonProps & {
  hidden: boolean
  index: number
  title: string
  onClick (): void
}

export type MapMenuProps = {
  items: MapMenuItem[]
}

function MapMenu (props: MapMenuProps) {
  const { items } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const sortedItems = [...items].sort((a, b) => (a.index - b.index))
  const submenuItems = useMemo(() => (
    sortedItems.length === 1 ? [] : sortedItems.filter((el) => el.hidden)
  ), [sortedItems])

  // S'il n'y a qu'un seul élément dans le sous-menu,
  // alors on force son affichage direct pour éviter
  // d'avoir à cliquer 2 fois pour accéder au seul élément du menu.
  const visibleItems = useMemo(() => (
    sortedItems.length === 1
      ? sortedItems
      : sortedItems.filter((el) => !el.hidden)
  ), [sortedItems])

  const toggleDropdown = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setIsOpen((s) => !s)
  }, [])

  useEventListener(document.body, 'click', () => {
    setIsOpen(false)
  })

  if (submenuItems.length === 0 && visibleItems.length === 0) {
    return null
  }

  return (
    <Group
      id="map-footer-menu"
      className="unselectable"
      gap={0}
      px={5}
      wrap="nowrap"
    >
      {submenuItems.length > 0
        ? <Menu
          opened={isOpen}
          withArrow
        >
          <Menu.Target>
            <MapMenuButton
              active={isOpen}
              icon={(
                <Burger
                  size="sm"
                  opened={isOpen}
                  onClick={toggleDropdown}
                />
              )}
              label="Menu"
              onClick={toggleDropdown}
            />
          </Menu.Target>

          <Menu.Dropdown>
            {sortedItems.filter((el) => el.hidden)
              .map((item) => (
                <Menu.Item
                  key={item.label}
                  color={item.active ? 'active' : undefined}
                  leftSection={item.icon}
                  onClick={item.onClick}
                  title={item.title}
                >
                  {item.label}
                </Menu.Item>
              ))}
          </Menu.Dropdown>
        </Menu>
        : null}

      {/* Affiche les items non inclus dans le sous-menu */}
      {visibleItems.map((item) => (
        <MapMenuButton
          active={item.active}
          key={item.label}
          icon={item.icon}
          label={item.label}
          onClick={item.onClick}
          title={item.title}
        />
      ))}
    </Group>
  )
}

export default MapMenu
