import { formatDistance, formatDuration } from '../../../lib/format'
import Feature from 'ol/Feature'
import { RouteType } from './useRouteContext'
import { randomString } from '../../../lib/random'

export type RouteOptions = {
  features: Feature[]
  id: string
  name: string
  type: RouteType
}

/**
 * Classe représentant un itinéraire.
 */
class Route {
  public readonly duration: number
  public readonly length: number
  public readonly segments: Feature[]

  constructor (options: RouteOptions) {
    this.segments = []

    let totalDuration = 0
    let totalLength = 0

    // Prépare les features des segments
    for (let i = 0; i < options.features?.length; i += 1) {
      const feature = options.features[i]
      const properties = feature.getProperties()

      feature.setId(randomString())
      feature.set('label', properties.nom_rue)
      feature.set('type', options.type)
      feature.set('index', i)

      feature.set('formattedDuration', formatDuration(properties.cost))

      if (properties.longueur != null) {
        feature.set('formattedLength', formatDistance(properties.longueur))
      }

      // feature.set('city', properties.commune)
      // feature.set('duration', properties.cost)
      // feature.set('length', properties.longueur)

      this.segments.push(feature)

      // Prépare les infos du segment à afficher dans le panneau.
      // this.segments.push({
      //   city: properties.commune,
      //   duration: properties.cost,
      //   length: properties.longueur,
      //   feature,
      //   formattedLength: properties.formattedLength,
      //   formattedDuration: properties.formattedDuration,
      //   idClassement: properties.id_classement,
      //   index: i,
      //   streetName: properties.nom_rue
      // })

      totalDuration += properties.cost
      totalLength += properties.longueur
    }
    this.duration = totalDuration
    this.length = totalLength
  }
}

export default Route
