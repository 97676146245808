import useQueryParams from './useQueryParams'

/**
 * Retourne les boutons affichés via l'URL.
 */
function useButtonsParam () {
  const [queryParams] = useQueryParams()
  return typeof queryParams.buttons === 'string'
    ? queryParams.buttons.split(',')
    : null
}

export default useButtonsParam
