import { faBiking } from '@fortawesome/free-solid-svg-icons/faBiking'
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar'
import { faRunning } from '@fortawesome/free-solid-svg-icons/faRunning'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parseField } from '@jalik/form-parser'
import { Box, Center, Checkbox, Modal, SegmentedControl, Stack, Text } from '@mantine/core'
import { getRouteTypeLabel } from '../../../lib/routing'
import useRouteContext from './useRouteContext'
import React from 'react'

/**
 * Retourne la valeur du champ modifié dans une callback.
 * @param setter
 */
function handleValueChangeBis (setter: (value: boolean) => void) {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    if (typeof currentTarget.checked === 'boolean' && /true|false/i.test(
      currentTarget.value)) {
      setter(currentTarget.checked)
    } else {
      setter(parseField(currentTarget, { trim: false }))
    }
  }
}

/**
 * Modale de configuration de l'itinéraire.
 */
function RouteSettingsModal () {
  const {
    avoidGates,
    routeType,
    setAvoidGates,
    setRouteType,
    showRouteTypeModal,
    toggleRouteTypeModal
  } = useRouteContext()

  return (
    <Modal
      centered
      opened={showRouteTypeModal}
      size="sm"
      onClose={toggleRouteTypeModal}
      title="Réglages de l'itinéraire"
    >
      <Stack>
        <Checkbox
          checked={!!avoidGates}
          data-type="boolean"
          id="avoidGatesField"
          name="avoidGates"
          label="Éviter les portails"
          onChange={handleValueChangeBis(setAvoidGates)}
          type="checkbox"
          value="true"
        />
        <Box>
          <Text c="dimmed">Choisissez un moyen de transport.</Text>
          <SegmentedControl
            fullWidth
            name="type"
            onChange={setRouteType}
            value={routeType}
            data={[
              {
                label: (
                  <Center>
                    <FontAwesomeIcon
                      icon={faCar}
                      fixedWidth
                    />
                    <Text ml="sm">
                      {getRouteTypeLabel('voiture')}
                    </Text>
                  </Center>
                ),
                value: 'voiture'
              },
              {
                label: (
                  <Center>
                    <FontAwesomeIcon
                      icon={faBiking}
                      fixedWidth
                    />
                    <Text ml="sm">
                      {getRouteTypeLabel('velo')}
                    </Text>
                  </Center>
                ),
                value: 'velo'
              },
              {
                label: (
                  <Center>
                    <FontAwesomeIcon
                      icon={faRunning}
                      fixedWidth
                    />
                    <Text ml="sm">
                      {getRouteTypeLabel('pieton')}
                    </Text>
                  </Center>
                ),
                value: 'pieton'
              }
            ]}
          >
          </SegmentedControl>
        </Box>
      </Stack>
    </Modal>
  )
}

export default RouteSettingsModal
