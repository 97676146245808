import { useCallback, useMemo, useState } from 'react'

/**
 * Hooks de compteur.
 * @param initialValue
 */
function useCounter (initialValue: number = 0) {
  const [value, setValue] = useState<number>(initialValue)

  const decrement = useCallback((number = 1) => {
    setValue((i) => Math.max(0, i - number))
  }, [])

  const increment = useCallback((number = 1) => {
    setValue((i) => i + number)
  }, [])

  return useMemo(() => ({
    decrement,
    increment,
    setValue,
    value
  }), [decrement, increment, value])
}

export default useCounter
