import { Alert, Modal, ModalProps, Text } from '@mantine/core'
import { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * Modale d'erreur.
 * @param props
 */
function ErrorModal (props: ModalProps): ReactElement {
  const {
    children,
    onClose,
    ...others
  } = props

  return (
    <Modal
      centered
      title={(
        <Text c="red">
          <FormattedMessage
            id="error"
            defaultMessage="Erreur"
          />
        </Text>
      )}
      {...others}
      onClose={onClose}
    >
      <Alert color="red">
        {children}
      </Alert>
    </Modal>
  )
}

export default ErrorModal
