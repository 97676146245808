import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { CurrentModule, CurrentModuleContext } from '../../hooks/useCurrentModuleContext'
import { createLogger } from '../../lib/logger'

const logger = createLogger({ name: 'module' })

/**
 * Fournisseur du contexte de l'outil actuel.
 * @param props
 */
function CurrentModuleContextProvider (props: PropsWithChildren) {
  const { children } = props
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false)
  const [module, setModule] = useState<string>(null)

  const setCurrentModule = useCallback((value: string) => {
    logger.debug(`Show module ${value}`)
    setModule(value)
    setIsPanelOpen(true)
  }, [])

  const toggleModule = useCallback((value: string) => {
    logger.debug(`Toggle module ${value}`)
    if (module !== value) {
      setModule(value)
      setIsPanelOpen(true)
    } else if (isPanelOpen) {
      setIsPanelOpen(false)
    } else {
      setIsPanelOpen(true)
    }
  }, [isPanelOpen, module])

  const togglePanel = useCallback(() => {
    setIsPanelOpen((s) => !s)
  }, [])

  useEffect(() => {
    if (isPanelOpen) {
      logger.debug('Show panel')
    } else {
      logger.debug('Hide panel')
    }
  }, [isPanelOpen])

  const providerContext: CurrentModule = useMemo(() => ({
    currentModule: module,
    isPanelOpen,
    setCurrentModule,
    setIsPanelOpen,
    toggleModule,
    togglePanel
  }), [isPanelOpen, module, setCurrentModule, toggleModule, togglePanel])

  return (
    <CurrentModuleContext.Provider value={providerContext}>
      {children}
    </CurrentModuleContext.Provider>
  )
}

export default CurrentModuleContextProvider
