import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Center, Group, Loader, Modal, Stack, Text } from '@mantine/core'
import { toBlob } from 'html-to-image'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BTN_DOWNLOAD_ID } from '../../../enums/UI'
import { saveAsFile } from '../../../lib/files'
import { logger } from '../../../lib/logger'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import useMapContext from '../../map/useMapContext'

/**
 * Bouton permettant de télécharger la carte sous forme d'image.
 */
function MapDownloadButton () {
  const { map } = useMapContext()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [error, setError] = useState<Error>(null)
  const cancelRef = useRef(false)

  const exportMap = useCallback(() => {
    logger.debug('Generating map to image')
    toBlob(map.getTargetElement())
      .then((blob) => {
        if (!cancelRef.current) {
          const date = DateTime.now()
            .toLocaleString(DateTime.DATETIME_SHORT)
          saveAsFile(blob, `Carte ${date}.png`)
        }
      })
      .catch((err) => {
        logger.error(err.message)
        setError(err)
      })
      .finally(() => {
        setDownloading(false)
        cancelRef.current = false
      })
  }, [map])

  const handleCancelDownload = useCallback(() => {
    logger.debug('Canceled map download')
    map.un('rendercomplete', exportMap)
    cancelRef.current = true
    setDownloading(false)
  }, [exportMap, map])

  const handleDownload = useCallback(() => {
    logger.debug('Waiting for map to be loaded')
    cancelRef.current = false
    setDownloading(true)
    map.once('rendercomplete', exportMap)
    map.render()
  }, [exportMap, map])

  useEffect(() => () => {
    map.un('rendercomplete', exportMap)
  }, [exportMap, map])

  return (
    <>
      <ConfigurableUIComponent
        id={BTN_DOWNLOAD_ID}
        showByDefault
      >
        <MapToolbarButton
          onClick={handleDownload}
          onKeyUp={handleDownload}
          title="Télécharger la carte sous forme d'image"
        >
          <FontAwesomeIcon
            icon={downloading ? faSpinner : faDownload}
            fixedWidth
            spin={downloading}
          />
        </MapToolbarButton>
      </ConfigurableUIComponent>

      <Modal
        centered
        closeOnClickOutside={false}
        opened={downloading}
        onClose={handleCancelDownload}
        size="xs"
        withCloseButton={false}
      >
        <Stack>
          <Center>
            {error
              ? <Group gap="sm">
                <FontAwesomeIcon
                  color="red"
                  icon={faTimesCircle}
                  fixedWidth
                />
                <Text c="red">{error.message}</Text>
              </Group>
              : <Group gap="sm">
                <Loader />
                <Text>Génération de la carte...</Text>
              </Group>
            }
          </Center>
          <Group grow>
            <Button
              fullWidth={!error}
              onClick={handleCancelDownload}
              variant="light"
            >
              Annuler
            </Button>
            {error
              ? <Button onClick={handleDownload}>
                Réessayer
              </Button>
              : null}
          </Group>
        </Stack>
      </Modal>
    </>
  )
}

export default MapDownloadButton
