import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons/faCloudDownloadAlt'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useCallback } from 'react'
import { BTN_CACHING_ID } from '../../../enums/UI'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import useId from '../../../hooks/useId'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import { LAYER_CACHE_MODULE } from './LayerCachingContextProvider'
import useLayerCacheContext from './useLayerCacheContext'
import { useTileLoader } from './useTileLoader'

/**
 * Bouton permettant de tracer une zone à mettre en cache pour un accès
 * hors-connexion.
 */
function LayerCacheButton () {
  const {
    currentModule,
    isPanelOpen,
    toggleModule
  } = useCurrentModuleContext()

  const { tileLoader } = useLayerCacheContext()
  const { running } = useTileLoader(tileLoader)

  const handleClick = useCallback(() => {
    toggleModule(LAYER_CACHE_MODULE)
  }, [toggleModule])

  const targetId = useId()

  return (
    <ConfigurableUIComponent
      id={BTN_CACHING_ID}
      showByDefault
    >
      <MapToolbarButton
        active={currentModule === LAYER_CACHE_MODULE && isPanelOpen}
        id={targetId}
        onClick={handleClick}
        title="Gestion du cache des zones"
      >
        <FontAwesomeIcon
          icon={running ? faSpinner : faCloudDownloadAlt}
          spin={running}
        />
      </MapToolbarButton>
    </ConfigurableUIComponent>
  )
}

export default LayerCacheButton
