import { DateTime } from 'luxon'
import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { TimeLineContext, TimeLineContext_ } from './useTimeLineContext'

const initialState = {
  time: DateTime.now().startOf('day').toISO()
}

function TimeLineContextProvider (props: PropsWithChildren) {
  const { children } = props
  const [state, setState] = useState(initialState)

  const setTime = useCallback((time: string) => {
    setState((s) => ({ ...s, time }))
  }, [])

  const providerContext = useMemo<TimeLineContext_>(() => ({
    ...state,
    setTime
  }), [setTime, state])

  return (
    <TimeLineContext.Provider value={providerContext}>
      {children}
    </TimeLineContext.Provider>
  )
}

export default TimeLineContextProvider
