import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactElement, useCallback } from 'react'
import { BTN_ZOOM_ID } from '../../../enums/UI'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import useMapContext from '../../map/useMapContext'
import { ActionIcon } from '@mantine/core'

/**
 * Boutons de zoom avant et arrière.
 */
function MapZoomButtons (): ReactElement {
  const { map } = useMapContext()
  const view = map.getView()

  const handleZoomIn = useCallback(() => {
    view.animate({
      zoom: view.getZoom() + 1,
      duration: 500
    })
  }, [view])

  const handleZoomOut = useCallback(() => {
    view.animate({
      zoom: view.getZoom() - 1,
      duration: 500
    })
  }, [view])

  return (
    <>
      <ConfigurableUIComponent
        id={BTN_ZOOM_ID}
        showByDefault
      >
        <ActionIcon.Group orientation="vertical">
          <MapToolbarButton
            onClick={handleZoomIn}
            onKeyUp={handleZoomIn}
            title="Zoomer en avant"
          >
            <FontAwesomeIcon
              icon={faPlus}
              fixedWidth
            />
          </MapToolbarButton>
          <MapToolbarButton
            onClick={handleZoomOut}
            onKeyUp={handleZoomOut}
            title="Zoomer en arrière"
          >
            <FontAwesomeIcon
              icon={faMinus}
              fixedWidth
            />
          </MapToolbarButton>
        </ActionIcon.Group>
      </ConfigurableUIComponent>
    </>
  )
}

export default MapZoomButtons
