import { DEVICE_PIXEL_RATIO } from 'ol/has'
import WMTS from 'ol/source/WMTS'
import { logger } from '../../../lib/logger'
import OlMap from 'ol/Map'
import { Extent } from 'ol/extent'
import TileLayer from 'ol/layer/Tile'
import { Observer } from '@jalik/observer'

export type TilesLoaderOptions = {
  abortOnError: boolean
  extent: Extent
  maxConcurrent: number
  minZoom: number
  maxZoom: number
}

type Event = 'update' | 'start' | 'finish' | 'stop'

/**
 * Gestionnaire de chargement de tuiles.
 */
class TilesLoader {
  private calculating: boolean
  private finished: number
  private options: TilesLoaderOptions
  private running: boolean
  private stopped: boolean
  private total: number
  private events: Observer<this, Event>
  private failed: Set<string>
  private queue: Set<string>
  private map: OlMap

  constructor (map: OlMap, options?: TilesLoaderOptions) {
    this.map = map
    this.calculating = false
    this.finished = 0
    this.options = { ...options }
    this.running = false
    this.stopped = false
    this.total = 0
    this.events = new Observer(this)
    this.failed = new Set()
    this.queue = new Set()
  }

  /**
   * Compte le nombre de tuiles à télécharger.
   */
  async calculateTiles () {
    this.calculating = true
    this.total = 0
    this.finished = 0
    this.events.emit('update')

    try {
      const layers = this.map.getLayers().getArray()
      const {
        extent,
        minZoom,
        maxZoom
      } = this.options

      for (let zoom = minZoom; zoom <= maxZoom; zoom += 1) {
        for (let i = 0; i < layers.length; i += 1) {
          const layer = layers[i]

          if (layer instanceof TileLayer) {
            const source = layer.getSource()

            // Met uniquement en cache les couches WMTS visibles.
            if (layer.getVisible() && source instanceof WMTS) {
              const tileGrid = source.getTileGrid()

              // Met en cache les tuiles de la couche pour le niveau de zoom.
              if (zoom <= tileGrid.getMaxZoom()) {
                tileGrid.forEachTileCoord(extent, zoom, () => {
                  this.total += 1
                })
              }
            }
          }
        }
      }
    } finally {
      this.calculating = false
      this.events.emit('update')
    }
  }

  fetchUrl (url: string) {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img')

      img.onerror = (err) => {
        if (this.queue.delete(url)) {
          this.failed.add(url)
        }
        reject(err)
      }

      img.onload = () => {
        if (this.queue.delete(url)) {
          this.finished += 1
        }
        resolve(img)
      }

      img.src = url
      this.queue.add(url)
    })
  }

  getFailed () {
    return this.failed.size
  }

  getFinished () {
    return this.finished
  }

  getRemaining () {
    return this.getTotal() - this.getFinished()
  }

  getTotal () {
    return this.total
  }

  isCalculating () {
    return this.calculating
  }

  isRunning () {
    return this.running
  }

  on (event: Event, listener: (...args: unknown[]) => void) {
    this.events.on(event, listener)
  }

  un (event: Event, listener: (...args: unknown[]) => void) {
    this.events.off(event, listener)
  }

  setOptions (opts: TilesLoaderOptions) {
    this.options = { ...opts }
  }

  async start () {
    // Replace les URL échouées dans la liste des URL à télécharger.
    if (this.failed.size > 0) {
      this.failed.forEach((url) => {
        this.failed.delete(url)
      })
    }
    await this.calculateTiles()
    this.finished = 0
    // this.total = 0
    this.stopped = false
    this.running = true
    this.events.emit('start')

    let promises = []
    const layers = this.map.getLayers()
      .getArray()
    const projection = this.map.getView()
      .getProjection()
    const {
      abortOnError,
      extent,
      maxConcurrent,
      minZoom,
      maxZoom
    } = this.options

    // Prépare la liste des URL à télécharger.
    for (let i = 0; i < layers.length; i += 1) {
      if (this.stopped) {
        break
      }
      const layer = layers[i]

      if (layer instanceof TileLayer) {
        const source = layer.getSource()

        // Met uniquement en cache les couches WMTS visibles.
        if (layer.getVisible() && source instanceof WMTS) {
          const tileGrid = source.getTileGrid()
          const getTileUrl = source.getTileUrlFunction.bind(source)()

          for (let z = minZoom; z <= maxZoom; z += 1) {
            if (this.stopped) {
              break
            }
            // Limite le zoom au max du TileGrid.
            if (z <= tileGrid.getMaxZoom()) {
              const {
                minX,
                minY,
                maxX,
                maxY
              } = tileGrid.getTileRangeForExtentAndZ(extent, z)

              for (let x = minX; x <= maxX; x += 1) {
                if (this.stopped) {
                  break
                }
                for (let y = minY; y <= maxY; y += 1) {
                  if (this.stopped) {
                    break
                  }
                  // Ajoute l'URL à charger.
                  const url = getTileUrl([z, x, y], DEVICE_PIXEL_RATIO, projection)
                  promises.push(this.fetchUrl(url))

                  // Déclenche le téléchargement du batch lorsque la limite a été atteinte.
                  if (promises.length >= maxConcurrent) {
                    try {
                      // eslint-disable-next-line no-await-in-loop
                      await Promise.all(promises)
                      promises = []
                      this.events.emit('update')
                    } catch (err) {
                      promises = []
                      logger.error(err)

                      if (abortOnError) {
                        throw err
                      }
                    }
                  }
                }
                // Télécharge les tuiles restantes.
                if (promises.length > 0) {
                  await Promise.all(promises)
                  promises = []
                  this.events.emit('update')
                }
              }
            }
          }
        }
      }
    }
    this.running = false
    this.events.emit('update')
    if (!this.stopped) {
      this.events.emit('finish')
    }
  }

  stop () {
    this.stopped = true
    this.events.emit('stop')
  }
}

export default TilesLoader
