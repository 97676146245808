import { RouteType } from './useRouteContext'
import Route from './Route'
import { Coordinate } from 'ol/coordinate'

export type RouteState = {
  arrivalCoordinate?: Coordinate
  avoidGates: boolean
  calculating: boolean
  departureCoordinate?: Coordinate
  error?: Error
  isPlayerOpen: boolean
  route?: Route
  routeType: RouteType
  showRemoveModal: boolean
  showRouteTypeModal: boolean
  showToolbar: boolean
}

export type RouteAction =
  { type: 'CALCULATE' } |
  { type: 'CLEAR_ERROR' } |
  { type: 'CLICK_ROUTE' } |
  { type: 'CLICK_OUT' } |
  { type: 'DELETE' } |
  { type: 'HIDE_MODULE' } |
  {
    type: 'INIT',
    data: {
      arrivalCoordinate: Coordinate
      departureCoordinate: Coordinate
      routeType: RouteType
      avoidGates: boolean
    }
  } |
  { type: 'INVERSE_MARKERS' } |
  {
    type: 'SET_AVOID_GATES',
    data: { avoidGates: boolean }
  } |
  {
    type: 'SET_ERROR',
    error: Error
  } |
  {
    type: 'SET_ARRIVAL',
    data: { coordinate: Coordinate }
  } |
  {
    type: 'SET_DEPARTURE',
    data: { coordinate: Coordinate }
  } |
  {
    type: 'SET_ROUTE',
    data: { route: Route }
  } |
  {
    type: 'SET_ROUTE_TYPE',
    data: { routeType: RouteType }
  } |
  { type: 'TOGGLE_PLAYER' } |
  { type: 'TOGGLE_REMOVE_MODAL' } |
  { type: 'TOGGLE_ROUTE_TYPE_MODAL' }

function RouteReducer (state: RouteState, action: RouteAction) {
  const { type } = action

  switch (type) {
    case 'CALCULATE':
      return {
        ...state,
        calculating: true,
        error: null,
        isPlayerOpen: false,
        route: null
      }
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null
      }
    case 'CLICK_ROUTE':
      return {
        ...state,
        showToolbar: true
      }
    case 'CLICK_OUT':
      return {
        ...state,
        isPlayerOpen: false,
        showToolbar: false
      }
    case 'DELETE':
      return {
        ...state,
        arrivalCoordinate: null,
        departureCoordinate: null,
        route: null,
        isPlayerOpen: false,
        showRemoveModal: false,
        showRouteTypeModal: false,
        showToolbar: false
      }
    case 'HIDE_MODULE':
      return {
        ...state,
        isPlayerOpen: false,
        showRemoveModal: false,
        showRouteTypeModal: false,
        showToolbar: false
      }
    case 'INIT':
      return {
        ...state,
        arrivalCoordinate: action.data.arrivalCoordinate,
        departureCoordinate: action.data.departureCoordinate,
        routeType: action.data.routeType,
        avoidGates: action.data.avoidGates
      }
    case 'INVERSE_MARKERS':
      return {
        ...state,
        arrivalCoordinate: state.departureCoordinate,
        departureCoordinate: state.arrivalCoordinate
      }
    case 'SET_AVOID_GATES':
      return {
        ...state,
        avoidGates: action.data.avoidGates
      }
    case 'SET_ERROR':
      return {
        ...state,
        calculating: false,
        error: action.error,
        route: null
      }
    case 'SET_ARRIVAL':
      return {
        ...state,
        arrivalCoordinate: action.data.coordinate,
        isPlayerOpen: false,
        showRemoveModal: false
      }
    case 'SET_DEPARTURE':
      return {
        ...state,
        departureCoordinate: action.data.coordinate,
        isPlayerOpen: false,
        showRemoveModal: false
      }
    case 'SET_ROUTE':
      return {
        ...state,
        route: action.data.route,
        calculating: false,
        error: null,
        showToolbar: true
      }
    case 'SET_ROUTE_TYPE':
      return {
        ...state,
        routeType: action.data.routeType
      }
    case 'TOGGLE_PLAYER':
      return {
        ...state,
        isPlayerOpen: !state.isPlayerOpen
      }
    case 'TOGGLE_REMOVE_MODAL':
      return {
        ...state,
        showRemoveModal: !state.showRemoveModal
      }
    case 'TOGGLE_ROUTE_TYPE_MODAL':
      return {
        ...state,
        showRouteTypeModal: !state.showRouteTypeModal
      }
  }
}

export default RouteReducer
