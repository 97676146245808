import Circle from 'ol/style/Circle'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { createTextStyleFromFeature } from '../../../lib/mapStyles'
import Feature from 'ol/Feature'

/**
 * Créé un style pour la mesure.
 * @param feature
 */
export function createMeasureStyle (feature: Feature): Style[] {
  return [
    new Style({
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineCap: 'butt',
        width: 4
      })
    }),
    new Style({
      image: new Circle({
        radius: 10,
        stroke: new Stroke({
          color: 'rgba(30,229,255,0.9)',
          width: 2
        })
      }),
      fill: new Fill({
        color: 'rgba(30,229,255,0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(30,229,255,0.9)',
        lineCap: 'butt',
        lineDash: [8, 4],
        width: 3
      }),
      text: createTextStyleFromFeature(feature)
    })
  ]
}
