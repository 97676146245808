import { Coordinate } from 'ol/coordinate'
import Observable from 'ol/Observable'
import { toRadians } from 'ol/math'

function randomValue (min: number, max: number): number {
  let value
  do {
    value = Math.random() * max
  } while (value >= min && value <= max)
  return value
}

type Position = {
  accuracy: number
  position: Coordinate
  altitude?: number
  heading?: number
  speed?: number
}

const positions: Position[] = [
  {
    accuracy: 10,
    heading: toRadians(91),
    position: [-149.61122327471747, -17.63426269525419]
  },
  {
    accuracy: 10,
    heading: toRadians(91),
    position: [-149.6107404770948, -17.63425733083616]
  },
  {
    accuracy: 18,
    heading: toRadians(91),
    position: [-149.6102753054171, -17.63425464862715]
  },
  {
    accuracy: 25,
    heading: toRadians(91),
    position: [-149.60973886361413, -17.634273424090253]
  },
  {
    accuracy: 30,
    heading: toRadians(91),
    position: [-149.6093365322619, -17.634251966418134]
  },
  {
    accuracy: 15,
    heading: toRadians(91),
    position: [-149.60882810823264, -17.6342466020001]
  },
  {
    accuracy: 12,
    heading: toRadians(91),
    position: [-149.6084230946714, -17.634238555373056]
  },
  {
    accuracy: 9,
    heading: toRadians(91),
    position: [-149.60790811054054, -17.6342466020001]
  },
  {
    accuracy: 6,
    heading: toRadians(176.13),
    position: [-149.60789469949546, -17.634445085467203]
  },
  {
    accuracy: 5,
    heading: toRadians(-131.53),
    position: [-149.60827020875752, -17.634777679385053]
  },
  {
    accuracy: 8,
    heading: toRadians(-129.13),
    position: [-149.60858670942127, -17.635035171450482]
  },
  {
    accuracy: 10,
    position: [-149.60905973710734, -17.6355752543619]
  },
  {
    accuracy: 10,
    position: [-149.60933332242686, -17.6360982851198]
  },
  {
    accuracy: 10,
    position: [-149.60945670404155, -17.636696417730125]
  },
  {
    accuracy: 10,
    position: [-149.6094996193858, -17.637259681623252]
  },
  {
    accuracy: 10,
    position: [-149.60953448810298, -17.63783635656145]
  },
  {
    accuracy: 12,
    position: [-149.6095023015948, -17.638260145585804]
  },
  {
    accuracy: 8,
    position: [-149.6095183948489, -17.6384398535898]
  },
  {
    accuracy: 10,
    position: [-149.60940574207027, -17.638536413114338]
  },
  {
    accuracy: 12,
    position: [-149.60929845370967, -17.638421078126697]
  },
  {
    accuracy: 15,
    position: [-149.60938160218913, -17.63826282779482]
  }
]

class GeolocationDebug extends Observable {
  private accuracy: number
  private altitude: number
  private heading: number
  private position: Coordinate
  private speed: number
  private tracking: boolean
  private interval: any

  private positionIndex: number = 0
  private currentAccuracy: number
  private currentAltitude: number
  private currentHeading: number
  private currentPosition: Coordinate
  private currentSpeed: number

  constructor () {
    super()
    this.accuracy = null
    this.altitude = null
    this.heading = null
    this.position = null
    this.speed = null
    this.tracking = false
  }

  getAccuracy () {
    return this.accuracy
  }

  getAltitude () {
    return this.altitude
  }

  getHeading () {
    return this.heading
  }

  getPosition () {
    return this.position
  }

  getSpeed () {
    return this.speed
  }

  getTracking () {
    return this.tracking
  }

  setAccuracy (accuracy: number) {
    if (accuracy === this.accuracy) return
    this.accuracy = accuracy
    this.dispatchEvent('change:accuracy')
  }

  setAltitude (altitude: number) {
    if (altitude === this.altitude) return
    this.altitude = altitude
    this.dispatchEvent('change:altitude')
  }

  setHeading (heading: number) {
    if (heading === this.heading) return
    this.heading = heading
    this.dispatchEvent('change:heading')
  }

  setPosition (position: Coordinate) {
    if (position === this.position) return
    this.position = position
    this.dispatchEvent('change:position')
    this.dispatchEvent('change')
  }

  setSpeed (speed: number) {
    if (speed === this.speed) return
    this.speed = speed
    this.dispatchEvent('change:speed')
  }

  setTracking (tracking: boolean) {
    if (tracking === this.tracking) return
    this.tracking = tracking
    this.dispatchEvent('change:tracking')

    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
    if (tracking) {
      this.interval = setInterval(() => {
        const pos = positions[this.positionIndex]
        this.currentAccuracy = pos.accuracy
        this.currentAltitude = pos.altitude
        this.currentHeading = pos.heading
        this.currentPosition = pos.position
        this.currentSpeed = pos.speed

        if (this.positionIndex === positions.length - 1) {
          this.positionIndex = 0
        } else {
          this.positionIndex += 1
        }

        if (this.currentPosition) {
          this.accuracy = this.currentAccuracy != null
            ? this.currentAccuracy
            : randomValue(1, 30)
          this.altitude = this.currentAltitude != null
            ? this.currentAltitude
            : randomValue(5, 12)
          this.heading = this.currentHeading
          this.speed = this.currentSpeed
          this.position = this.currentPosition
          this.dispatchEvent('change')
        }
      }, 5000)
    }
  }
}

export default GeolocationDebug
