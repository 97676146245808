import EsriJSON from 'ol/format/EsriJSON'
import GeoJSON from 'ol/format/GeoJSON'
import GPX from 'ol/format/GPX'
import KML from 'ol/format/KML'
import DragAndDrop from 'ol/interaction/DragAndDrop'
import { useMemo } from 'react'
import useMapInteraction from './useMapInteraction'
import OlMap from 'ol/Map'

function useMapDragAndDropInteraction (map: OlMap): DragAndDrop {
  const interaction = useMemo(() => (
    new DragAndDrop({
      formatConstructors: [
        new EsriJSON(),
        new GeoJSON(),
        new GPX(),
        new KML()
      ]
    })
  ), [])
  // Ajoute l'interaction de dépôt de fichier sur la carte (drag-and-drop).
  useMapInteraction(map, interaction)
  return interaction
}

export default useMapDragAndDropInteraction
