import { parseField } from '@jalik/form-parser'
import { inputValue } from '@jalik/react-form'
import { Group, TextInput } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useSearchContext from '../search/useSearchContext'
import IntegrateModal, { IframeTools, MapIframeOptions } from './IntegrateModal'
import ShareModal from './ShareModal'
import { getMapCode, MapURLOptions } from './sharing'
import useMapUrl from '../../../hooks/useMapUrl'
import { Coordinate } from 'ol/coordinate'

export type ShareCoordinateModalProps = {
  coordinate: Coordinate
  defaultLabel?: string
  isEditable?: boolean
  onClose (): void
  show: boolean
}

type ModalFields = {
  label?: string
}

/**
 * Modale de partage de coordonnées.
 */
function ShareCoordinateModal (props: ShareCoordinateModalProps) {
  const {
    coordinate,
    defaultLabel,
    isEditable = false,
    onClose,
    show
  } = props

  const { searchQuery } = useSearchContext()
  const [fields, setFields] = useState<ModalFields>({ label: defaultLabel })
  const [isIframeModalOpen, setIsIframeModalOpen] = useState<boolean>(false)

  const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    const value = parseField(currentTarget, { trim: false })
    setFields((f) => ({
      ...f,
      [currentTarget.name]: value
    }))
  }, [])

  const toggleIframeModal = useCallback(() => {
    setIsIframeModalOpen((s) => !s)
  }, [])

  // Prépare les options du lien de partage.
  const mapOptions = useMemo<MapURLOptions>(() => ({
    center: coordinate,
    info: { coordinate, label: fields.label },
    search: searchQuery
  }), [coordinate, fields.label, searchQuery])

  // Prépare les options de l'iframe.
  const iframeOptions = useMemo<MapIframeOptions>(() => ({
    ...mapOptions,
    embed: true,
    buttons: IframeTools.map((el) => el.id),
    height: 600,
    width: 800
  }), [mapOptions])

  const mapUrl = useMapUrl(mapOptions)
  const iframeUrl = useMapUrl({ ...mapOptions, embed: true })
  const mapCode = useMemo<string>(() => getMapCode(iframeUrl), [iframeUrl])

  useEffect(() => {
    if (show) {
      setFields({ label: defaultLabel })
    }
  }, [coordinate, defaultLabel, show])

  return (
    <>
      <IntegrateModal
        onClose={toggleIframeModal}
        options={iframeOptions}
        show={isIframeModalOpen}
      />

      <ShareModal
        code={mapCode}
        link={mapUrl}
        onClose={onClose}
        onIframeSettingsClick={toggleIframeModal}
        show={show && !isIframeModalOpen}
      >
        <Group wrap="nowrap" align="end">
          <TextInput
            w="100%"
            disabled={!isEditable}
            id="labelField"
            name="label"
            label="Libellé du marqueur"
            description="Ajoutez sur la carte un marqueur avec ou sans libellé."
            onChange={handleFieldChange}
            value={inputValue(fields.label)}
          />
        </Group>
      </ShareModal>
    </>
  )
}

export default ShareCoordinateModal
