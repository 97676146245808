import { createContext, useContext } from 'react'

export type AuthContext_ = {
  details?: UserDetails
  isAuthModalOpen: boolean
  isConnected: boolean
  isExpired: boolean
  isPending: boolean
  setIsAuthModalOpen: (open: boolean) => void
  signIn: (username: string, password: string) => Promise<any>
  signOut: () => Promise<any>
}

/**
 * Contexte de l'utilisateur actuel.
 */
export const AuthContext = createContext<AuthContext_>(null)

/**
 * Retourne le contexte d'authentification.
 */
function useAuthContext () {
  return useContext(AuthContext)
}

export default useAuthContext
