import { Text } from '@mantine/core'
import { useIntl } from 'react-intl'

function ForbiddenAccess () {
  const intl = useIntl()
  return (
    <Text c="red" size="xl">
      {intl.formatMessage({
        id: 'forbidden_access',
        defaultMessage: 'Accès interdit'
      })}
    </Text>
  )
}

export default ForbiddenAccess
