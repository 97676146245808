import { useEffect } from 'react'
import OlMap from 'ol/Map'
import Overlay from 'ol/Overlay'

/**
 * Hook permettant d'ajouter un overlay à la carte.
 * @param map
 * @param overlay
 */
function useMapOverlay (map: OlMap, overlay: Overlay) {
  useEffect(() => {
    if (map && overlay) {
      map.addOverlay(overlay)
    }
    return () => {
      if (map && overlay) {
        map.removeOverlay(overlay)
      }
    }
  }, [map, overlay])
}

export default useMapOverlay
