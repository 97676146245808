import { useCallback, useState } from 'react'

function useObjectState<S> (initialState: S): [S, (attrs: Partial<S>) => void] {
  const [state, setState] = useState<S>(initialState)

  const setAttributes = useCallback((attributes: Partial<S>) => {
    setState((s) => ({ ...s, ...attributes }))
  }, [setState])

  return [state, setAttributes]
}

export default useObjectState
