import { Alert, Button, Group, Modal, Select, Stack, Text, TextInput } from '@mantine/core'
import Feature from 'ol/Feature'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import ExportFormats, { ExportFormat, FORMAT_GPX } from '../../../enums/ExportFormats'
import { handleFieldChange, handleValueChange } from '../../../lib/form'
import { CIRCLE_RADIUS_PROP, containsGeometries } from '../../../lib/geometry'

export type ExportFeaturesOptions = {
  filename?: string
  format?: ExportFormat
}

export type ExportFeaturesModalProps = {
  defaultOptions: ExportFeaturesOptions
  features: Feature[]
  onClose (): void
  onValidate (options: ExportFeaturesOptions, features: Feature[]): void
  show: boolean
}

/**
 * Modale d'exportation des features.
 */
function ExportFeaturesModal (props: ExportFeaturesModalProps) {
  const {
    defaultOptions,
    features,
    onClose,
    onValidate,
    show
  } = props

  const [fields, setFields] = useState<ExportFeaturesOptions>(defaultOptions)

  const has = useMemo(() => ({
    circle: containsGeometries(['Circle'], features),
    linestring: containsGeometries(['LineString'], features),
    multiLineString: containsGeometries(['MultiLineString'], features),
    multiPoint: containsGeometries(['MultiPoint'], features),
    multiPolygon: containsGeometries(['MultiPolygon'], features),
    point: containsGeometries(['Point'], features),
    polygon: containsGeometries(['Polygon'], features)
  }), [features])

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    onValidate(fields, features)
  }, [features, fields, onValidate])

  const setField = useCallback((name: keyof ExportFeaturesOptions, value: string) => {
    setFields((s) => ({
      ...s,
      [name]: value
    }))
  }, [setFields])

  useEffect(() => {
    if (show) {
      setFields((s) => ({
        ...s,
        filename: defaultOptions?.filename
      }))
    }
  }, [defaultOptions?.filename, setFields, show])

  const unsupportedGeometries = useMemo(() => {
    const types = []

    if (fields.format === FORMAT_GPX) {
      if (has.multiPoint) {
        types.push('MultiPoint')
      }
      if (has.multiPolygon) {
        types.push('MultiPolygon')
      }
      if (has.polygon) {
        types.push('Polygon')
      }
    }
    return types
  }, [fields.format, has.multiPoint, has.multiPolygon, has.polygon])

  return (
    <Modal
      centered
      opened={show}
      onClose={onClose}
      title="Exportation des dessins"
    >
      <form
        method="post"
        onSubmit={handleSubmit}
      >
        <Stack>
          <Select
            allowDeselect={false}
            data-type="string"
            id="formatField"
            label="Format"
            name="format"
            onChange={handleValueChange('format', setField)}
            required
            value={fields.format || ''}
            data={ExportFormats}
          />

          {unsupportedGeometries.length > 0
            ? <Alert color="red">
              Attention, le format {fields.format} ne supporte pas les géométries&nbsp;
              {unsupportedGeometries.map((el) => `"${el}"`).join(', ')}, elles ne seront pas
              exportées. Si vous souhaitez les conserver, choisissez un autre format.
            </Alert>
            : null}

          {has.circle
            ? <Alert color="yellow">
              {`Note : Les cercles vont être remplacés par des points avec l'attribut "${CIRCLE_RADIUS_PROP}".`}
            </Alert>
            : null}

          <TextInput
            data-type="string"
            label="Nom du fichier"
            name="filename"
            onChange={handleFieldChange(setField)}
            required
            value={fields.filename || ''}
            rightSectionWidth={100}
            rightSection={(
              <Text>
                {`.${fields.format?.toLowerCase()}`}
              </Text>
            )}
          />

          <Group justify="right">
            <Button
              onClick={onClose}
              variant="light"
            >
              Annuler
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
            >
              Exporter
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ExportFeaturesModal
