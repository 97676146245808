import { Text, ThemeIcon, UnstyledButton, UnstyledButtonProps } from '@mantine/core'
import { ForwardedRef, forwardRef, HTMLAttributes, ReactElement, ReactNode } from 'react'
import useCurrentModuleContext from '../../hooks/useCurrentModuleContext'

export type MapMenuButtonProps = UnstyledButtonProps & {
  active?: boolean
  icon: ReactNode
  label: string
}

/**
 * Bouton du menu de carte.
 * @param props
 * @param ref
 */
function MapMenuButton (
  props: HTMLAttributes<HTMLButtonElement> & UnstyledButtonProps & MapMenuButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const {
    active = false,
    icon,
    label,
    onClick,
    title,
    ...others
  } = props

  const { isPanelOpen } = useCurrentModuleContext()

  return (
    <UnstyledButton
      {...others}
      ref={ref}
      onClick={onClick}
      title={title}
      w={50}
      ta="center"
      style={{ pointerEvents: 'auto' }}
    >
      <ThemeIcon
        c={active && isPanelOpen ? 'active' : 'primary'}
        radius="xl"
        size="lg"
        variant="default"
      >
        {icon}
      </ThemeIcon>
      <Text
        mt={6}
        c="white"
        fw="bold"
        ff="Roboto"
        size="0.65rem"
        tt="uppercase"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textShadow: '0 0 2px rgba(0,0,0,0.6)',
          whiteSpace: 'nowrap'
        }}
      >
        {label}
      </Text>
    </UnstyledButton>
  )
}

export default forwardRef(MapMenuButton)
