import { createContext, useContext } from 'react'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Coordinate } from 'ol/coordinate'

export type GeolocationInfo = {
  accuracy?: number
  altitude?: number
  calculatedHeadingDeg?: number
  calculatedSpeedKph?: number
  date: Date
  distance?: number
  elapsedSeconds?: number
  heading?: number
  headingDeg180?: number
  headingRad?: number
  position?: Coordinate
  refreshDate?: Date
  rotation?: number
  rotationDate?: Date
  rotationPosition?: Coordinate
  speed?: number
  speedKph?: number
}

export type GeolocationContext_ = {
  addCustomMarker (): void
  error?: Error
  geolocationLayer: VectorLayer<VectorSource>
  info: GeolocationInfo
  markersLayer: VectorLayer<VectorSource>
  recording: boolean
  setTracking (track: boolean): void
  setTrackPosition (track: boolean): void
  toggleRecording (): void
  toggleShareModal (): void
  trackPosition: boolean
  tracking: boolean
}

/**
 * Contexte de géolocalisation.
 */
export const GeolocationContext = createContext<GeolocationContext_>(null)

/**
 * Retourne le contexte de géolocalisation.
 */
function useGeolocationContext () {
  return useContext(GeolocationContext)
}

export default useGeolocationContext
