import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { createTextStyleFromFeature } from '../../../lib/mapStyles'
import OlMap from 'ol/Map'
import Feature from 'ol/Feature'

/**
 * Créé un style pour les zones en cache.
 * @param map
 */
export function createCachedZoneStyle (map: OlMap) {
  return (feature: Feature, resolution: number): Style[] => {
    const props = feature.getProperties() || {}
    const zoom = Math.round(map.getView().getZoomForResolution(resolution))
    const isZoomLevelCached = zoom <= props.maxZoom && zoom >= props.minZoom
    return [
      new Style({
        fill: new Fill({
          color: isZoomLevelCached
            ? 'rgba(200,250,200,0.3)'
            : 'rgba(250,200,200,0.3)'
        }),
        stroke: new Stroke({
          color: isZoomLevelCached
            ? 'rgba(200,250,200,0.9)'
            : 'rgba(250,200,200,0.9)',
          lineCap: 'butt',
          lineDash: [8, 4],
          width: 3
        }),
        text: createTextStyleFromFeature(feature)
      })
    ]
  }
}
