import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Menu } from '@mantine/core'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import useAuthContext from './useAuthContext'
import useCurrentUser from './useCurrentUser'
import { useNetwork } from '@mantine/hooks'

function getUserInitials (name: string): string {
  if (name != null) {
    const parts = name.split(/ +/, 2)
    if (parts.length > 1) {
      return parts.map((el) => el.substring(0, 1).toUpperCase()).join('')
    }
    return parts.map((el) => el.substring(0, 2)).join('')
  }
  return '?'
}

function AuthButton () {
  const {
    isConnected,
    isPending,
    setIsAuthModalOpen,
    signOut
  } = useAuthContext()

  const networkStatus = useNetwork()
  const user = useCurrentUser()

  const openModal = useCallback(() => {
    setIsAuthModalOpen(true)
  }, [setIsAuthModalOpen])

  const handleLogoutClick = useCallback(() => {
    signOut()
  }, [signOut])

  return isConnected
    ? <Menu
      withArrow
      withinPortal
    >
      <Menu.Target>
        <Avatar
          component="button"
          radius="xl"
          variant="gradient"
          style={{ border: 'none', cursor: 'pointer' }}
        >
          {getUserInitials(user?.displayName || user?.username)}
        </Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          {user?.displayName || user?.username}
        </Menu.Label>

        {user?.roles?.includes('admin')
          ? <Menu.Item
            component={Link}
            to="/admin"
            leftSection={(
              <FontAwesomeIcon
                icon={faCog}
                fixedWidth
              />
            )}
          >
            Administration
          </Menu.Item>
          : null}

        <Menu.Item
          onClick={handleLogoutClick}
          leftSection={(
            <FontAwesomeIcon
              icon={faSignOutAlt}
              fixedWidth
            />
          )}
        >
          Se déconnecter
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
    : <Button
      disabled={!networkStatus.online}
      px="xs"
      loading={isPending}
      onClick={openModal}
      title="Se connecter"
      type="button"
    >
      <FontAwesomeIcon
        icon={faUser}
        fixedWidth
      />
    </Button>
}

export default AuthButton
