import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notifications } from '@mantine/notifications'
import Collection from 'ol/Collection'
import Feature from 'ol/Feature'
import { fromExtent } from 'ol/geom/Polygon'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import useMapLayer from '../../../hooks/useMapLayer'
import usePersistentState from '../../../hooks/usePersistentState'
import { MARKER_Z_INDEX } from '../../../lib/mapStyles'
import useMapContext from '../../map/useMapContext'
import ConfirmModal from '../../modals/ConfirmModal'
import { createCachedZoneStyle } from './cacheStyle'
import TilesLoader from './TilesLoader'
import { LayerCacheContext, LayerCacheContext_ } from './useLayerCacheContext'
import OlMap from 'ol/Map'
import { Extent } from 'ol/extent'

export type CachedZone = {
  extent?: Extent
  id?: string
  maxConcurrent?: number
  maxZoom?: number
  minZoom?: number
  name?: string
}

const CACHE_STORAGE_ID = 'cachedZones'
export const LAYER_CACHE_MODULE = 'lcache'

/**
 * Retourne une couche de zones en cache.
 * @param map
 */
function createCachedZonesLayer (map: OlMap) {
  return new VectorLayer({
    source: new VectorSource({
      features: new Collection<Feature>()
    }),
    style: createCachedZoneStyle(map),
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    zIndex: MARKER_Z_INDEX
  })
}

/**
 * Retourne une feature correspond à une zone de cache.
 * @param props
 */
function createCachedZoneFeature (props: CachedZone) {
  return new Feature({
    ...props,
    geometry: fromExtent(props.extent)
  })
}

function LayerCacheContextProvider (props: PropsWithChildren) {
  const { children } = props
  const { map } = useMapContext()
  const { currentModule } = useCurrentModuleContext()
  const [zones, setZones] = usePersistentState<CachedZone[]>(CACHE_STORAGE_ID, [])
  const [zoneToDelete, setZoneToDelete] = useState<string>()
  const [zoneToEdit, setZoneToEdit] = useState<CachedZone>()

  const [layer] = useMemo(() => [createCachedZonesLayer(map)], [map])
  useMapLayer(map, layer)

  const tileLoader = useMemo(() => {
    return new TilesLoader(map)
  }, [map])

  useEffect(() => {
    const NOTIF_ID = 'layer-cache'
    const cb = () => {
      notifications.show({
        id: NOTIF_ID,
        autoClose: false,
        title: 'Chargement de zone terminé',
        message: 'La mise en cache de la zone est terminée.',
        color: 'info',
        icon: <FontAwesomeIcon icon={faInfo} />
      })
    }
    tileLoader.on('finish', cb)
    return () => {
      notifications.hide(NOTIF_ID)
      tileLoader.un('finish', cb)
    }
  }, [tileLoader])

  const handleDelete = useCallback(() => {
    setZones((s: CachedZone[]) => s.filter((el) => el.id !== zoneToDelete))
    setZoneToDelete(null)
  }, [zoneToDelete, setZones])

  useEffect(() => {
    layer.setVisible(currentModule === LAYER_CACHE_MODULE)
  }, [currentModule, layer])

  useEffect(() => {
    // Affiche les zones sur la carte.
    const features = zones
      .filter((el) => el.extent != null)
      .map(createCachedZoneFeature)
    layer.getSource()
      .clear(true)
    layer.getSource()
      .addFeatures(features)
  }, [layer, zones])

  const context = useMemo<LayerCacheContext_>(() => ({
    deleteZone: setZoneToDelete,
    setZoneToEdit,
    setZones,
    tileLoader,
    zoneToEdit,
    zones: zones.sort((a, b) => a?.name.localeCompare(b.name))
  }), [setZones, tileLoader, zoneToEdit, zones])

  return (
    <LayerCacheContext.Provider value={context}>
      {children}

      <ConfirmModal
        opened={zoneToDelete != null}
        title="Supprimer la zone"
        onClose={() => setZoneToDelete(null)}
        onConfirm={handleDelete}
      >
        Voulez-vous supprimer la zone ?
      </ConfirmModal>
    </LayerCacheContext.Provider>
  )
}

export default LayerCacheContextProvider
