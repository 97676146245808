import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { BTN_POSITION_ID } from '../../../enums/UI'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import { GEOLOCATION_MODULE } from './GeolocationContextProvider'
import useGeolocationContext from './useGeolocationContext'

/**
 * Bouton de carte permettant d'activer ou de désactiver la géolocalisation.
 */
function GeolocationButton () {
  const { setTracking, tracking } = useGeolocationContext()
  const { currentModule, setCurrentModule } = useCurrentModuleContext()

  const toggleTracking = useCallback(() => {
    if (tracking) {
      setTracking(false)
    } else if (currentModule !== GEOLOCATION_MODULE) {
      setTracking(true)
      setCurrentModule(GEOLOCATION_MODULE)
    } else {
      setCurrentModule(null)
    }
  }, [tracking, currentModule, setTracking, setCurrentModule])

  return (
    <ConfigurableUIComponent
      id={BTN_POSITION_ID}
      showByDefault
    >
      <MapToolbarButton
        active={tracking}
        onClick={toggleTracking}
        title={tracking
          ? 'Désactiver la géolocalisation'
          : 'Activer la géolocalisation'}
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          fixedWidth
        />
      </MapToolbarButton>
    </ConfigurableUIComponent>
  )
}

export default GeolocationButton
