import { useEffect } from 'react'
import OlMap from 'ol/Map'
import BaseLayer from 'ol/layer/Base'

/**
 * Hook permettant d'ajouter une couche à la carte.
 * @param map
 * @param layer
 */
function useMapLayer (map: OlMap, layer: BaseLayer) {
  useEffect(() => {
    if (map && layer && !map.getLayers().getArray().includes(layer)) {
      map.addLayer(layer)
    }
    return () => {
      if (map && layer) {
        map.removeLayer(layer)
      }
    }
  }, [map, layer])
}

export default useMapLayer
