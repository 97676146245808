import logo from '../../images/logo.svg'
import React, { ReactElement } from 'react'

function AppLogo (props: React.ImgHTMLAttributes<HTMLImageElement>): ReactElement {
  return (
    <img
      alt="Logo Te Fenua"
      {...props}
      src={logo}
    />
  )
}

export default AppLogo
