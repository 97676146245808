import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notifications } from '@mantine/notifications'
import { useCallback, useEffect, useState } from 'react'
import { BTN_ZOOM_BOX_ID } from '../../../enums/UI'
import useDrawBoxInteraction from '../../../hooks/useDrawBoxInteraction'
import useOLEventListener from '../../../hooks/useOLEventListener'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import useMapContext from '../../map/useMapContext'
import useInfoContext from '../info/useInfoContext'
import { DrawEvent } from 'ol/interaction/Draw'
import { SimpleGeometry } from 'ol/geom'

const NOTIF_DRAW_AREA = 'zoom-draw-area-help'

/**
 * Bouton de zoom sur une zone de la carte.
 */
function MapZoomBoxButton () {
  const { map } = useMapContext()
  const [drawing, setDrawing] = useState<boolean>(false)
  const drawBoxInteraction = useDrawBoxInteraction(map)
  const { setIsActive } = useInfoContext()

  const startDrawing = useCallback(() => {
    setDrawing(true)
  }, [])

  const stopDrawing = useCallback(() => {
    setDrawing(false)
    setIsActive(true)
  }, [setIsActive])

  const toggleDrawing = useCallback(() => {
    if (drawing) {
      stopDrawing()
    } else {
      startDrawing()
    }
  }, [drawing, startDrawing, stopDrawing])

  const handleZoomToBox = useCallback((event: DrawEvent) => {
    const geometry = event.feature.getGeometry()
    // Zoome sur le rectangle une fois dessiné.
    if (geometry instanceof SimpleGeometry) {
      map.getView().fit(geometry, {
        duration: 500,
        padding: [10, 10, 10, 10]
      })
    }
    stopDrawing()
    notifications.hide(NOTIF_DRAW_AREA)
  }, [map, stopDrawing])

  const handleKeyUp = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      stopDrawing()
    }
  }, [stopDrawing])

  useEffect(() => {
    drawBoxInteraction.setActive(drawing)

    if (drawing) {
      notifications.show({
        id: NOTIF_DRAW_AREA,
        autoClose: false,
        message: 'Cliquez pour tracer la zone sur laquelle zoomer.',
        color: 'info',
        icon: <FontAwesomeIcon icon={faInfo} />
      })
    } else {
      notifications.hide(NOTIF_DRAW_AREA)
    }
  }, [drawing, drawBoxInteraction, map])

  useOLEventListener(drawBoxInteraction, 'drawend', handleZoomToBox)

  useOLEventListener(map, 'click', () => {
    if (drawing) {
      // Désactive l'info pendant le tracé
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  })

  return (
    <ConfigurableUIComponent
      id={BTN_ZOOM_BOX_ID}
      showByDefault
    >
      <MapToolbarButton
        active={drawing}
        onClick={toggleDrawing}
        onKeyUp={handleKeyUp}
        title={drawing ? 'Annuler' : 'Sélectionner une zone à zoomer'}
      >
        <FontAwesomeIcon
          icon={faSearchPlus}
          fixedWidth
        />
      </MapToolbarButton>
    </ConfigurableUIComponent>
  )
}

export default MapZoomBoxButton
