import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Card } from '@mantine/core'
import { lazy, Suspense } from 'react'
import useCurrentModuleContext from '../hooks/useCurrentModuleContext'
import LoadingMessage from './LoadingMessage'
import { LAYERS_MODULE } from './map/MapContextProvider'
import useMapContext from './map/useMapContext'
import { LAYER_CACHE_MODULE } from './modules/caching/LayerCachingContextProvider'
import { DRAW_MODULE } from './modules/drawing/DrawContextProvider'
import { GEOLOCATION_MODULE } from './modules/geolocation/GeolocationContextProvider'
import { INFO_MODULE } from './modules/info/InfoContextProvider'
import { MEASURE_MODULE } from './modules/measure/MeasureContextProvider'
import { ROUTE_MODULE } from './modules/routing/RouteContextProvider'
import { SEARCH_MODULE } from './modules/search/SearchContextProvider'
import PanelBody from './PanelBody'

const DrawPanel = lazy(() => import('./modules/drawing/DrawPanel'))
const GeolocationPanel = lazy(() => import('./modules/geolocation/GeolocationPanel'))
const InfoPanel = lazy(() => import('./modules/info/InfoPanel'))
const LayerCachePanel = lazy(() => import('./modules/caching/LayerCachePanel'))
const LayersPanel = lazy(() => import('./modules/layers/LayersPanel'))
const MeasurePanel = lazy(() => import('./modules/measure/MeasurePanel'))
const RoutePanel = lazy(() => import('./modules/routing/RoutePanel'))
const SearchPanel = lazy(() => import('./modules/search/SearchPanel'))

export const PANEL_WIDTH = 360

function MapPanel () {
  const {
    currentModule,
    isPanelOpen,
    togglePanel
  } = useCurrentModuleContext()

  const {
    context,
    map
  } = useMapContext()

  return (
    <>
      {currentModule
        ? <Button
          id="map-panel-toggle"
          pos="absolute"
          h={45}
          w={30}
          p={0}
          onClick={togglePanel}
          style={{
            zIndex: 4,
            borderRadius: '0 3px 3px 0'
          }}
          variant="gradient"
        >
          <FontAwesomeIcon
            size="sm"
            fixedWidth
            icon={faChevronLeft}
            rotation={isPanelOpen ? undefined : 180}
          />
        </Button>
        : null}

      <Box
        id="map-panel"
        pos="absolute"
        left={isPanelOpen ? 0 : -PANEL_WIDTH}
        w="100%"
        h="100%"
        style={{
          zIndex: 1,
          maxWidth: PANEL_WIDTH,
          transition: 'left 500ms ease',
          // Permet le clic sur la carte à travers l'élément.
          pointerEvents: 'none'
        }}
      >
        <Card
          bg="rgba(255, 255, 255, 0.9)"
          mah="100%"
          p={0}
          shadow="md"
          withBorder={false}
          style={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
            pointerEvents: 'initial'
          }}
        >
          {map && context
            ? <Suspense
              fallback={(
                <PanelBody>
                  <LoadingMessage message="Initialisation en cours..." />
                </PanelBody>
              )}
            >
              {currentModule === LAYERS_MODULE
                ? <LayersPanel />
                : null}

              {currentModule === SEARCH_MODULE
                ? <SearchPanel />
                : null}

              {currentModule === INFO_MODULE
                ? <InfoPanel />
                : null}

              {currentModule === ROUTE_MODULE
                ? <RoutePanel />
                : null}

              {currentModule === GEOLOCATION_MODULE
                ? <GeolocationPanel />
                : null}

              {currentModule === DRAW_MODULE
                ? <DrawPanel />
                : null}

              {currentModule === MEASURE_MODULE
                ? <MeasurePanel />
                : null}

              {currentModule === LAYER_CACHE_MODULE
                ? <LayerCachePanel />
                : null}
            </Suspense>
            : null}
        </Card>
      </Box>
    </>
  )
}

export default MapPanel
