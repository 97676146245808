import { useCallback, useEffect, useRef } from 'react'

function useEventListener<K extends keyof HTMLElementEventMap> (
  target: HTMLElement,
  event: K,
  listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any
) {
  const funcRef = useRef(null)

  const callback = useCallback((ev: HTMLElementEventMap[K]) => {
    if (typeof funcRef.current === 'function') {
      funcRef.current(ev)
    }
  }, [])

  useEffect(() => {
    funcRef.current = listener
  }, [listener])

  useEffect(() => {
    if (target && event) {
      target.addEventListener(event, callback)
    }
    return () => {
      if (target && event) {
        target.removeEventListener(event, callback)
      }
    }
  }, [target, event, callback])
}

export default useEventListener
