import { Modal, ModalProps } from '@mantine/core'
import LoadingMessage, { LoadingMessageProps } from './LoadingMessage'
import { ReactElement, useCallback } from 'react'

function LoadingModal (props: Partial<ModalProps> & LoadingMessageProps): ReactElement {
  const {
    message,
    ...others
  } = props
  return (
    <Modal
      opened
      size="xs"
      centered
      onClose={useCallback(() => {
      }, [])}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      {...others}
    >
      <Modal.Body>
        <LoadingMessage message={message} />
      </Modal.Body>
    </Modal>
  )
}

export default LoadingModal
