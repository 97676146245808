import { createContext, SetStateAction, useContext } from 'react'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import { Coordinate } from 'ol/coordinate'

export type SearchResult = {
  feature: Feature<any>
}

export type SearchResults_ = {
  results: SearchResult[]
  totalElements: number
}

export type SearchContext_ = {
  isSearchHelpOpen: boolean
  resultCount: number
  searching: boolean
  searchCenter?: Coordinate
  searchCount: number
  searchLayer: VectorLayer<VectorSource>
  searchPage?: number
  searchQuery?: string
  searchZoom?: number
  setIsSearchHelpOpen (open: SetStateAction<boolean>): void
  setSearching (searching: SetStateAction<boolean>): void
  setSearchPage (page: number): void
  setSearchQuery (query: string): void
  startSearch (promise: Promise<SearchResults_>): Promise<void | SearchResults_>
  toggleSearchHelp (): void
  version: number
}

/**
 * Contexte de recherche.
 */
export const SearchContext = createContext<SearchContext_>(null)

/**
 * Retourne le contexte de recherche.
 */
function useSearchContext () {
  return useContext(SearchContext)
}

export default useSearchContext
