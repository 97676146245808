import { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { CachedZone } from './LayerCachingContextProvider'
import TilesLoader from './TilesLoader'

export type LayerCacheContext_ = {
  deleteZone: Dispatch<SetStateAction<string>>
  setZoneToEdit: Dispatch<SetStateAction<CachedZone>>
  setZones: Dispatch<SetStateAction<CachedZone[]>>
  tileLoader: TilesLoader
  zoneToEdit?: CachedZone
  zones: CachedZone[]
}

/**
 * Contexte du cache des couches.
 */
export const LayerCacheContext = createContext<LayerCacheContext_>(null)

/**
 * Retourne le contexte de cache des couches.
 */
function useLayerCacheContext () {
  return useContext(LayerCacheContext)
}

export default useLayerCacheContext
