import { ReactElement, useCallback, useState } from 'react'
import { acceptCookies, isCookiesAccepted, refuseCookies } from '../lib/cookies'
import {
  Anchor,
  Button,
  Center,
  Container,
  Group,
  Overlay,
  Paper,
  Stack,
  Text
} from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'

/**
 * Bandeau permettant d'accepter ou de refuser les cookies.
 * todo utiliser un contexte react pour gérer l'autorisation des cookies
 */
function CookiesAlert (): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(!isCookiesAccepted())

  const handleAccept = useCallback(() => {
    acceptCookies()
    setIsOpen(false)
  }, [])

  const handleRefuse = useCallback(() => {
    refuseCookies()
    setIsOpen(false)
  }, [])

  return (
    <Overlay hidden={!isOpen}>
      <Paper
        radius={0}
        p="sm"
        pos="fixed"
        left={0}
        bottom={0}
        w="100%"
      >
        <Container>
          <Center>
            <Stack>
              <Group wrap="nowrap">
                <Text c="blue">
                  <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                </Text>
                <Text>
                  <FormattedMessage
                    id="cookie_message"
                    defaultMessage="Nous utilisons des {cookies}
             ou équivalents afin d'enregistrer vos préférences et de permettre l'authentification."
                    values={{
                      cookies: (
                        <Anchor
                          href="https://fr.wikipedia.org/wiki/Cookie_(informatique)"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="cookies"
                            defaultMessage="Cookies"
                          />
                        </Anchor>
                      )
                    }}
                  />
                </Text>
              </Group>
              <Group wrap="nowrap" gap="xs" justify="center">
                <Button
                  data-autofocus
                  onClick={handleRefuse}
                  variant="outline"
                >
                  <FormattedMessage
                    id="refuse"
                    defaultMessage="Refuser"
                  />
                </Button>
                <Button
                  onClick={handleAccept}
                >
                  <FormattedMessage
                    id="accept"
                    defaultMessage="Accepter"
                  />
                </Button>
              </Group>
            </Stack>
          </Center>
        </Container>
      </Paper>
    </Overlay>
  )
}

export default CookiesAlert
