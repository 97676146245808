import { createContext, SetStateAction, useContext } from 'react'
import DrawInteraction from 'ol/interaction/Draw'
import VectorSource from 'ol/source/Vector'
import SelectInteraction from 'ol/interaction/Select'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'

export type MeasureShape = 'LineString' | 'Polygon' | 'Circle' | 'Box'

export type MeasureContext_ = {
  clear (): void
  deleteById (id: string): void
  featureToRemove?: Feature
  isActive: boolean
  isListOpen: boolean
  measureInteraction: DrawInteraction
  measureLayer: VectorLayer<VectorSource>
  measureShape: MeasureShape
  selectInteraction: SelectInteraction
  selectedFeatures: Feature[]
  setFeatureToRemove (feature: SetStateAction<Feature>): void
  setIsActive (active: SetStateAction<boolean>): void
  setIsListOpen (active: SetStateAction<boolean>): void
  setMeasureShape (shape: SetStateAction<MeasureShape>): void
  toggleClearModal (): void
}

/**
 * Contexte de mesure.
 */
export const MeasureContext = createContext<MeasureContext_>(null)

/**
 * Retourne le contexte de mesure.
 */
function useMeasureContext () {
  return useContext(MeasureContext)
}

export default useMeasureContext
